import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

import {
    FaCashRegister, FaWallet, 
    FaUserPlus, FaArrowCircleUp, FaCheckCircle } from "react-icons/fa";
import {AiOutlineCheckCircle, AiFillFire} from "react-icons/ai";
import {GiStoneBridge} from "react-icons/gi";
import {GoAlert} from "react-icons/go";

import { Page} from "../components/Page";
import { Spinner } from "../components/Base";
import { ReservePills,BuyersPills, CRUPills, BenefitClaimsPills } from "../components/PillsWidgets";
import { useAuthStore } from "../store";
import { RecursiveHashedInfoViewer } from "../components/RecursiveHashedInfoViewer";
import { GrapheneService } from "../services/GrapheneService";
import { fromHexToCurrency, formatDate, RoleType } from "../utils";
import { XrplPayloadService } from "../services/XrplPayloadService";

import { RetireModal } from "../components/RetireModal";
import { SignSendModal } from "../components/SignSendModel";
import { WalletSelection } from "../components/WalletSelection";

import {apiConfig} from "../env";


const TrustlineBalanceInfo = ({trustline, balance, wallet}) => {

    const [error, setError] = useState(null);
    const [walletSendCount, setWalletSendCount] = useState(0);
    const [walletReceiveCount, setWalletRecieveCount] = useState(0);

    useEffect(() => {
        // console.log("TrustlineBalanceInfo", trustline, balance);

        if (trustline) {
            let sumSend = 0;
            let sumRecieve = 0;

            for (let i = 0; i < trustline.payment_txs.length; i++) {
                const obj = trustline.payment_txs[i];
                if (Number.isInteger(obj.Amount.value) && obj.Destination === wallet.classic_address) {
                    sumRecieve += obj.Amount.value;
                }
                if (Number.isInteger(obj.Amount.value) && obj.Account === wallet.classic_address) {
                    sumSend += obj.Amount.value;
                }
                
            }
            setWalletRecieveCount(sumRecieve);
            setWalletSendCount(sumSend);
        }

    }, [trustline, balance]);

    return (
        <div className="flex flex-col">
            {/* <div className="flex flex-row justify-start font-bold">Trustline Balance</div> */}
            {trustline &&
            <div className="flex flex-wrap items-baseline">
                <div className="font-mono font-bold text-3xl text-yellow-300 mr-4">{trustline.tokenSymbol}</div>
                      
                <div className="trustline-metric">
                    <div className="text-xs">Issued</div>
                    <div className="text-3xl">{trustline.value}</div>
                </div>
                <div className="trustline-metric">
                    <div className="text-xs">Asserted</div>
                    <div className="text-3xl">{balance}</div>
                </div>
                <div className="trustline-metric">
                    <div className="text-xs">Received</div>
                    <div className="text-3xl">{walletReceiveCount}</div>
                </div>
                <div className="trustline-metric">
                    <div className="text-xs">Sent</div>
                    <div className="text-3xl">{walletSendCount}</div>
                </div>
                <div className="trustline-metric">
                    <div className="text-xs">Balance</div>
                    <div className="text-3xl">{walletReceiveCount-walletSendCount}</div>
                </div>

            </div>}
        </div>
    );
};                

export const ConveyanceUploader = ({trustlineId, setIsLoading, 
    conveyanceDocument, 
    setConveyanceDocument, type='.pdf'}) => {

    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
 
    const changeHandler = (event) => {
          setSelectedFile(event.target.files[0]);
          setIsSelected(true);
      };
  
    const handleSubmission = () => { 
        setIsLoading(true);
        GrapheneService.uploadConveyanceDocument(trustlineId, selectedFile)
        .then((response) => {
            console.log(response);
            setIsLoading(false);
            setConveyanceDocument(response.data);
        })
        .catch((error) => {
            console.error('Error:', error);
            setIsLoading(false);
        });
      };
  
    return (
        <>
            {conveyanceDocument ? 
            <div>
                <div className="p-3 m-2 rounded bg-green-200 border-green-600 border text-green-700 items-center flex flex-row">
                    <AiOutlineCheckCircle className="text-3xl"/>Upload Successful
                </div>
            </div>:
            <div className="mt-2">
            <label className="block mb-2 
            text-gray-900 dark:text-white" 
            forname="file_input">Upload Assertion Conveyance Document</label>
            <input id="file_input" className="p-1 block w-full text-gray-900 
            border border-gray-300 rounded
            cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
            type="file" name="file" onChange={changeHandler}/>
            {isSelected &&<div>
                    <div className="btn-common" onClick={handleSubmission}>Upload</div>
                </div>}            
            </div>}        
        </>

    );
};

export const AssertedClaimStatus = ({status}) => {
    return (
      <>
        {/* {status === 'created' && <div className="div-pill bg-yellow-700 w-fit">created</div>}
        {status === 'pinned' && <div className="div-pill bg-pink-700 w-fit">pinned</div>}
        {status === 'minted' && <div className="div-pill bg-purple-700 w-fit">minted</div>}
        {status === 'issuerSet' && <div className="div-pill bg-green-700 w-fit">issuer</div>} */}
        {status === 'PAID' && <div className="div-pill bg-green-700 w-fit">PAID</div>}      
      </>
    )
};

export const PaidAssertedClaimsList = ({claims}) => {

    return (
        <div className="flex flex-col">
            <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
                <thead>
                    <tr>
                        {/* <th>Status</th> */}
                        <th>Date</th>
                        <th>Claimed Benefits</th>
                        <th>Payment</th>
                        <th scope="col" className="text-center">Actions</th> 
                    </tr>
                </thead>
                <tbody>
            {claims.map((item, index) => (                
                <tr key={index} className="mb-1">
                    <td className="align-top">{item?.payment?.datetime ? formatDate(item?.payment?.datetime) : new Date().toISOString()}</td>
                    <td className="align-top">{item?.benefitClaimsAsserted ? 
                        <BenefitClaimsPills benefitClaims={item.benefitClaimsAsserted} 
                            showTitle={false}/>:
                        <>No Asserttions</>}
                    </td>
                    <td className="align-top">
                        {item?.payment?.amount?.currency && <div className="font-mono font-bold text-lg text-yellow-200">
                            {/* {console.log("item",item)} */}
                            {item?.payment?.amount?.value} {fromHexToCurrency(item?.payment?.amount?.currency)}
                        </div>}
                    </td>

                    <td scope="col" className="text-center align-top">

                        <div className="flex flex-row items-center justify-center">

                            <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${item.payment.txHash}`,'_blank')} className="text-cyan-200 hover:text-yellow-300 m-1 underline cursor-pointer">
                                <FaCashRegister />
                            </div>
     
                            <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/accounts/${item.payment.destination}`,'_blank')} className="text-cyan-200 hover:text-yellow-300 m-1 underline cursor-pointer">
                                <FaWallet/>
                            </div>
                            
                        </div>                        
                    </td> 
                </tr>

            ))}
            </tbody>
            </table>
        </div>
        
    );

};

/**
 * 
 * @param {}
 * 
 * Quantity in tonnes
Project:
   Name
    Type
    Location
    Description
    URL to public page on registry
    Project id on registry
    SDGs
Vintage:
    Year
    URL to public page on registry (optional)} param0 

{
  "amount":2,
  "grapheneTlcsId":3,
  "bridgeType":"THALLO",
  "project":{
    "name": "awesome project",
    "location": "str",
    "description": "str",
    "publicUrl": "str",
    "grapheneTlcsId": "str",
    "type": "CO2",
    "sdgs": ["e2b8ac58-b685-4c10-882e-3be14b78631"]
  },
  "vintage":{
    "year": 2012
  }
}

 * @returns 
 */
export const SendCRUsBridgeModal = ({
    bridgeWalletId,
    showModal, 
    setShowModal,
    handler
}) => {

    const [error, setError] = useState(null);   
    const [isLoading, setIsLoading] = useState(false);
    const [numberCRUs, setNumberCRUs] = useState(0);

    const handleSendCRUs = async () => {
        console.log("handleSendCRUs", numberCRUs, bridgeWalletId);
        setIsLoading(true);
        setShowModal(false);
        //should verify here
        handler(numberCRUs, bridgeWalletId);
    };

    return (
        <> 
        {showModal &&
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
                <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
                    <div className="rounded bg-cyan-200 w-[450] p-2 text-slate-800">
                        {error && <div className="error rounded bg-red-200 text-red-700">{error}</div>}
                        <div className="text-lg font-bold flex flex-row">
                            <div>Send CRUs To Bridge Wallet</div>                       
                            {isLoading && <div className="ml-2"><Spinner /></div>}
                        </div>
                        <div className="flex flex-col w-full justify-start">
                            <div className="mt-2">
                                <label htmlFor="numberCRUs">Number of CRUs To Bridge</label>
                                <input
                                    name="numberCRUs"
                                    onChange={(e)=>setNumberCRUs(parseInt(e.target.value))}
                                    className="text-sm font-mono rounded border-2 border-slate-300 p-2 w-full"
                                    type="text"
                                    placeholder="Number of CRUs" />
                            </div>
                        </div>

                        <div className="flex flex-row justify-end">
                            <button className="btn-common" onClick={()=>setShowModal(false)}>Cancel</button>
                            <button className="btn-common" onClick={()=>handleSendCRUs()}>Send To Bridge Wallet</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>);
};

export const ChooseWalletModal = ({
    wallets,
    showModal, 
    setShowModal,
    handler,
    title="Choose Wallet"
}) => {

    // const [error, setError] = useState(null);   
    // const [isLoading, setIsLoading] = useState(false);
    const [walletId, setWalletId] = useState(0);

    const handleSetWallet = async () => {
        // setIsLoading(true);
        setShowModal(false);
        handler(walletId);
    };


    return (
        <> 
        {showModal &&
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
                <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
                    <div className="rounded bg-cyan-200 w-[450] p-2 text-slate-800">
                        {/* {error && <div className="error rounded bg-red-200 text-red-700">{error}</div>} */}
                        <div className="text-lg font-bold flex flex-row">
                            <div>{title}</div>                       
                            {/* {isLoading && <div className="ml-2"><Spinner /></div>} */}
                        </div>
                        <div className="flex flex-col w-full justify-start">
                            <div className="mt-2">
                                <label htmlFor="numberCRUs">Choose Wallet</label>
                                <WalletSelection filterBridge={true} 
                                    wallets={wallets} setWalletId={setWalletId}/>
                            </div>
                        </div>  

                        <div className="flex flex-row justify-end">
                            <button className="btn-common" onClick={()=>setShowModal(false)}>Cancel</button>
                            <button className="btn-common" onClick={()=>handleSetWallet()}>Set Wallet</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>);
};

export const ChooseBridgeModal = ({
    wallets,
    showModal, 
    setShowModal,
    handler
}) => {

    const [error, setError] = useState(null);   
    const [isLoading, setIsLoading] = useState(false);
    const [bridgeWalletId, setBridgeWalletId] = useState(0);

    const handleSetBridge = async () => {
        setIsLoading(true);
        setShowModal(false);
        handler(bridgeWalletId);
    };

    const handleSetWallet = (walletId) => {
        console.log("walletId", walletId);
        setBridgeWalletId(parseInt(walletId));
    };

    return (
        <> 
        {showModal &&
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
                <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
                    <div className="rounded bg-cyan-200 w-[450] p-2 text-slate-800">
                        {error && <div className="error rounded bg-red-200 text-red-700">{error}</div>}
                        <div className="text-lg font-bold flex flex-row">
                            <div>Add Bridge To Trustline</div>                       
                            {isLoading && <div className="ml-2"><Spinner /></div>}
                        </div>
                        <div className="flex flex-col w-full justify-start">
                            <div className="mt-2">
                                <label htmlFor="numberCRUs">Choose Bridge Wallet</label>
                                <WalletSelection filterBridge={true} 
                                    wallets={wallets} setWalletId={handleSetWallet}/>
                            </div>
                        </div>  

                        <div className="flex flex-row justify-end">
                            <button className="btn-common" onClick={()=>setShowModal(false)}>Cancel</button>
                            <button className="btn-common" onClick={()=>handleSetBridge()}>Add Wallet</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>);
};

export const AssertClaimsModal = ({
    showModal, 
    setShowModal, 
    setAssertionInfo,
    assertionInfo,
    conveyanceDocument,
    setConveyanceDocument,
    setTrustlineClaims, 
    benefitClaims, 
    isLoading, 
    setIsLoading}) => {

    const { id } = useParams();
    const [error, setError] = useState(null);
    const [selectedValues, setSelectedValues] = useState();  

    const [benefitsList, setBenefitsList] = useState([
        {name: 'Option 1', key: "OPTION_1"},
        {name: 'Option 2', key: "OPTION_2"}]);

    useEffect(() => {
        setConveyanceDocument(null);
        setSelectedValues(null);
        setError(null);
        // now get all the benefits for this project[0].project.modularBenefitProjects
        const benefitsList = benefitClaims.map((claim) => {
            console.log("claim", claim);
            return {name: claim.name, id: claim.id, quantity: claim.quantity, key: `${claim.name} ${claim.quantity}`}
        });
        setBenefitsList(benefitsList);

    }, [benefitClaims, showModal]);

    const onSelect = (selectedList, selectedItem) => {
        setSelectedValues(selectedList);
    }
  
    const onChangeValue = (e) => {
        console.log("e", e);
        setAssertionInfo({ ...assertionInfo, [e.target.name]: e.target.value });
    }

    const onRemove = (selectedList, removedItem) => {
        setSelectedValues(selectedList);
    }

    const handleAssertTrustlineClaims = async (selectedValues) => {
        console.log("selectedValues", selectedValues);
        setTrustlineClaims(selectedValues);
    };

    return (
    <> 
    {showModal &&
        <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
            <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
                <div className="rounded bg-cyan-200 w-[450] p-2 text-slate-800">

                    {error && <div className="error rounded bg-red-200 text-red-700">{error}</div>}
                    <div className="text-lg font-bold flex flex-row">
                        <div>Assert Trustline Claims</div>                       
                        {isLoading && <div className="ml-2"><Spinner /></div>}
                    </div>
                    {benefitClaims && 
                    <>
                        <div className="mb-2">
                            <label htmlFor="project">Choose the Benefits</label>
                            <Multiselect
                                className="bg-white border border-gray-300 rounded"
                                options={benefitsList} // Options to display in the dropdown
                                displayValue="key" // Property name to display in the dropdown options
                                selectedValues={selectedValues} // Preselected value to persist in dropdown
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                placeholder="Select Trustline Benefits Claims"
                            />
                        </div>
                        <div>
                                <label htmlFor="project">Choose the Assertion Amount of tCO2e</label>
                                <input
                                    name="assertionValueSelected"
                                    onChange={onChangeValue} 
                                    className="text-sm font-mono rounded border-2 border-slate-300 p-2 w-full" 
                                    type="text" 
                                    placeholder="Amount tCO2e" />
                        </div>
                        {/* <div>
                            <VintageList onChange={onChangeValue}  />
                        </div> */}

                        <ConveyanceUploader trustlineId={id} setIsLoading={setIsLoading} setConveyanceDocument={setConveyanceDocument} conveyanceDocument={conveyanceDocument} />
                    </>}

                    <div className="flex flex-row justify-end">
                        <button className="btn-common" onClick={()=>setShowModal(false)}>Cancel</button>
                        {conveyanceDocument && <button className="btn-common" onClick={()=>handleAssertTrustlineClaims(selectedValues)}>Assert</button>}
                    </div>

                </div>
            </div>
        </div>}
    </>
    );
};

export const PayWalletModal = ({
    wallets,
    showModal,
    setShowModal,
    handler}) => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [walletId, setWalletId] = useState();
    const [numberCRUs, setNumberCRUs] = useState(0);

    const handleSetWallet = (walletId) => {
        console.log("walletId", walletId);
        setWalletId(parseInt(walletId));
    };

    const handlePayWallet = async () => {
        console.log("handlePayWallet", numberCRUs, walletId);
        setShowModal(false);
        handler(numberCRUs, walletId);
    };

    return (
    <>
    {showModal &&
        <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
            <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
                <div className="rounded bg-cyan-200 w-[450] p-2 text-slate-800">
                    
                    {error && <div className="error rounded bg-red-200 text-red-700">{error}</div>}
                    <div className="text-lg font-bold flex flex-row">
                        <div>Send CRUs To Wallet</div>                       
                        {isLoading && <div className="ml-2"><Spinner /></div>}
                    </div>
                    <div className="flex flex-col w-full justify-start">
                        <div className="mt-2">
                            <label htmlFor="numberCRUs">Number of CRUs To Send</label>
                            <input
                                name="numberCRUs"
                                onChange={(e)=>setNumberCRUs(parseInt(e.target.value))}
                                className="text-sm font-mono rounded border-2 border-slate-300 p-2 w-full"
                                type="text"
                                placeholder="Number of CRUs" />
                        </div>
                    </div>

                    <div className="flex flex-col w-full justify-start">
                            <div className="mt-2">
                                <label htmlFor="wallets">Choose Wallet</label>
                                <WalletSelection filterBridge={true} 
                                    wallets={wallets} setWalletId={handleSetWallet}/>
                            </div>
                        </div>  

                    <div className="flex flex-row justify-end">
                        <button className="btn-common" onClick={()=>setShowModal(false)}>Cancel</button>
                        <button className="btn-common" onClick={()=>handlePayWallet()}>Send CRUs</button>
                    </div>
  



                </div>
            </div>
        </div>}
    </>);

};


const TrustlineWallets = ({trustline}) => {

    const navigate = useNavigate();

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-start font-bold">Trustline Wallets</div>
            <div>

                {trustline && trustline?.issuerAccount && <div>Project Owner Wallet: 
                    <span onClick={()=>navigate(`/wallet/${trustline?.issuerAccount.id}`)}className="ml-2 link-common">{trustline?.issuerAccount.name}</span></div>}   

                {trustline && trustline?.distributorAccount && <div>Distributor Wallet: 
                    <span onClick={()=>navigate(`/wallet/${trustline?.distributorAccount.id}`)}className="ml-2 link-common">{trustline?.distributorAccount.name}</span></div>}   

                {trustline && trustline?.burnerAccount && 
                <div className="flex flex-row justify-start">
                    <span>Burn Wallet:</span> 
                    <span onClick={()=>navigate(`/wallet/${trustline?.burnerAccount.id}`)}className="ml-2 link-common">{trustline?.burnerAccount.name}</span>

                    {trustline?.burnerTrustlineTxHash ? 
                                <FaCheckCircle className="text-green-500 ml-1 text-2xl"/>:
                                <GoAlert className="text-yellow-500 ml-1 text-2xl"/>}              
                </div>}

                {trustline && trustline?.bridgeAccount && 
                <div className="flex flex-row justify-start">
                    <span>Bridge Wallet</span> 
                    <span onClick={()=>navigate(`/wallet/${trustline?.bridgeAccount.id}`)}className="ml-2 link-common">{trustline?.bridgeAccount.name}</span>
                    
                    {trustline?.bridgeTrustlineTxHash ? 
                                <FaCheckCircle className="text-green-500 ml-1 text-2xl"/>:
                                <GoAlert className="text-yellow-500 ml-1 text-2xl"/>}

                </div>}

            </div>
        </div>
    );
};

export const Trustline = ({
    xumm=null,
    runtime=null}) => {


    const { id } = useParams();
    const [error, setError] = useState(null);
    const [trustline, setTrustline] = useState(null);
    const [trustlineClaims, setTrustlineClaims] = useState([]);
    const [amountRemaining, setAmountRemaining] = useState(0);
    const [balance, setBalance] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    //wallets, needed for selecting wallets
    const [wallets, setWallets] = useState([]);

    /*= assert claims modal */
    const [showAssertModal, setShowAssertModal] = useState(false);

    const [conveyanceDocument, setConveyanceDocument] = useState(null);
    const [assertionInfo, setAssertionInfo] = useState({
        assertionVintage:"2018",
        assertionValueSelected: "0.0",
    });

    const [bridgeInfo, setBridgeInfo] = useState({
        bridgeType: "THALLO",
        amount: 0,
        vintage: 2018,
    });

    const [trustlineClaimsetId, setTrustlineClaimsetId] = useState(null);

    // sign and send modal
    const [showSignSendModal, setShowSignSendModal] = useState(false);

    // retire modal
    const [showRetireModal, setShowRetireModal] = useState(false);
    
    // bridge modal
    const [showChooseBridgeModal, setShowChooseBridgeModal] = useState(false);
    const [showSendToBridgeModal, setShowSendToBridgeModal] = useState(false);
    const [bridgeWalletId, setBridgeWalletId] = useState();

    // choose buyer modal
    const [showChooseBuyerModal, setShowChooseBuyerModal] = useState(false);
    const [buyerWalletId, setBuyerWalletId] = useState();

    // pay buyer modal
    const [showPayBuyerModal, setShowPayBuyerModal] = useState(false);

    const [signPayload, setSignPayload] = useState();

    const [txResult, setTxResult] = useState(null);
    const [role, setRole] = useState(null);

    const [wallet, setWallet] = useState(null);

    const navigate = useNavigate();
    
    useEffect(() => {
        console.log("assertionInfo", assertionInfo);
    }, [assertionInfo]);

    useEffect(() => {
        if (txResult) {
            console.log("txResult", txResult);
        }
    }, [txResult]);

    useEffect(() => {
        if (id) {
            let jwt_stored = useAuthStore.getState().jwt;
            // let classic_address = jwt.decode(jwt_stored).sub;
            //setUserAddress(classic_address);
            console.log("Trustline useEffect id", id);

            GrapheneService.getUser().then((r) => {
                console.log("user", r.data);
                // setUserAddress(r.data);
                const _wallet = r.data.default_wallet;
                setWallet(_wallet);
                GrapheneService.getClaimsetTrustline(id).then((r) => {
                    console.log("trustline", r.data);
                    setTrustline(r.data);

                    // if we have a bridge wallet then set the bridge wallet id
                    if(r.data?.bridgeAccount?.id){
                        setBridgeWalletId(r.data.bridgeAccount.id);
                    };

                    if(r.data.assertions && r.data.assertions.length>0){
                        setTrustlineClaims(r.data.assertions);
                    }

                    // filter the role of the default wallet
                    const role = r.data.addressRoles.find((role) => role.classic_address === _wallet.classic_address);
                    if (role && role?.roleType) {
                        setRole(role);
                    } else {
                        // check to see if this is the burner wallet
                        if (_wallet.classic_address === r.data.burnerAddress) { 
                            setRole({roleType: RoleType.burner});
                        }

                        // check to see if this is the burner wallet
                        if (_wallet.is_bridge === true) { 
                            setRole({roleType: RoleType.bridge});
                        }

                        if (r.data.distributorAddress === _wallet.classic_address) {
                            setRole({roleType: RoleType.distributor});
                        }

                        // check to see if this is a buyer wallet
                        r.data.buyerAccounts.forEach((buyerAccount) => {
                            if (_wallet.classic_address === buyerAccount.classic_address) { 
                                setRole({roleType: RoleType.buyer});
                            }
                        });
                    }
                    
                    setBalance(0);
                    setAmountRemaining(r.data.value);
                }).catch((err) => {
                    console.log("err", err);
                    setError(err);
                });            
            
            }).catch((err) => {
                console.log("err", err);
                setError(err);
            });      
        }
    }, [id]);
    
    useEffect(() => {
        if (trustlineClaims && trustlineClaims.length>0) {
            setIsLoading(true);
            console.log("trustlineClaims", trustlineClaims);
            const amountRemaining = trustlineClaims.reduce((acc, val) => {
                if(val!==undefined){
                    console.log("acc", acc, val);
                    if(val.payment && val.payment.amount && val.payment.amount.value)
                        return acc - val.payment.amount.value;
                    else
                        return acc;
                }
                
            }, trustline.value);
            setBalance(trustline.value-amountRemaining);
            setAmountRemaining(amountRemaining);

            // get wallets
            GrapheneService.getWallet()
            .then((res) => {
                setWallets(res.data);
                setIsLoading(false);
            });
        };

    }, [trustlineClaims]);
    
    const handleTrustlineClaims = (claimsList) => {
        console.log("handleTrustlineClaims", claimsList, trustline);

        // reduce the benefits from the trustlines as a list
        const trustlineBenefits = trustline.benefitClaims.reduce((acc, val) => {
            console.log("acc", acc);
            console.log("val", val);
            return acc.concat(val);
        }, []);
        console.log("trustlineBenefits", trustlineBenefits);

        const claimsToAssert = claimsList.map((claim) => {
            console.log("claim", claim);
            return trustlineBenefits.find((trustline) => trustline.id === claim.id);
        });

        console.log("claimsToAssert", claimsToAssert);
        console.log("conveyanceDocument", conveyanceDocument);
        
        setIsLoading(true);
        const amount = assertionInfo.assertionValueSelected;
        const vintage = assertionInfo.assertionVintage;

        XrplPayloadService.getAssertionPayload(id, claimsToAssert,  conveyanceDocument.ipfsHash, amount, vintage)
        .then((result) => {
            console.log("getAssertionPayload result", result.data);
            const payload = result.data;
            //lets sign and send the payload
            setSignPayload(payload);
            setShowAssertModal(false);
            setIsLoading(false);
            setShowSignSendModal(true);
            
        }).catch((error) => {
            console.log("getAssertionPayload error", error);
            if (error.response) {
                setError(error.response.data.detail);
            } else {
                setError("Problem asserting claims");
            }
            setIsLoading(false);
        });
            
    };

    const handleAssert = (trustline) => {
        console.log("Assert Claims", trustline);
        setShowAssertModal(true);
    };

    const handleRetire = (trustline) => {
        console.log("Retire", trustline);
        setTrustlineClaimsetId(id);
        setShowRetireModal(true);
    };

    const handleSetBridgeWallet = (bridgeWalletId) => {
        console.log("Add Trustline for Bridge", bridgeWalletId);
        setBridgeWalletId(bridgeWalletId);

        setIsLoading(true);
        GrapheneService.addProjectRole(trustline.grapheneProjectId, role?.roleType, bridgeWalletId)
        .then((res) => {
            console.log("res", res.data);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });

    };

    const handleAddBuyerWallet = (walletId) => {
        console.log("Add Buyer Wallet:"+walletId);
        setIsLoading(true);
        //projectId, roleName, wallet_id=null
        GrapheneService.addProjectRole(trustline.grapheneProjectId, RoleType.buyer, walletId)
        .then((res) => {
            console.log("res", res.data);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });

    };

    const handlePayBuyerWallet = (amount, buyerWalletId) => {
        console.log("Pay Buyer Wallet", amount, buyerWalletId);
        setIsLoading(true);

        // need to use the vintage from the trustline
        const sendRequest = {
            "amount":amount,
            "grapheneTlcsId":id,
            "destinationWalletId":buyerWalletId
        }

        XrplPayloadService.postSendCRUsPayload(sendRequest)
        .then((result) => {
            console.log("postBridgePayload result", result.data);
            const payload = result.data;
            //lets sign and send the payload
            setSignPayload(payload);
            setShowSendToBridgeModal(false);
            setIsLoading(false);
            setShowSignSendModal(true);
            
        }).catch((error) => {
            console.log("getAssertionPayload error", error);
            if (error.response) {
                setError(error.response.data.detail);
            } else {
                setError("Problem asserting claims");
            }
            setIsLoading(false);
        });
    };

    const handleSendToBridge = (amount, bridgeWalletId) => {
        console.log("Send to Bridge", amount, bridgeWalletId);

        setIsLoading(true);

        // need to use the vintage from the trustline
        const bridgeRequest = {
            "amount":amount,
            "grapheneTlcsId":id,
            "destinationWalletId": bridgeWalletId
        }

        XrplPayloadService.postSendCRUsPayload(bridgeRequest)
        .then((result) => {
            console.log("postSendCRUsPayload result", result.data);
            const payload = result.data;
            //lets sign and send the payload
            setSignPayload(payload);
            setShowSendToBridgeModal(false);
            setIsLoading(false);
            setShowSignSendModal(true);
            
        }).catch((error) => {
            console.log("getAssertionPayload error", error);
            if (error.response) {
                setError(error.response.data.detail);
            } else {
                setError("Problem asserting claims");
            }
            setIsLoading(false);
        });

    };

    const handleBridgeTrustline = () => {
        console.log("Bridge Trustline");
        setIsLoading(true);

        // need to use the vintage from the trustline
        const bridgeRequest = {
            "amount":trustline.value,
            "grapheneTlcsId":id
        }

        XrplPayloadService.postBridgeTrustlinePayload(bridgeRequest)
        .then((result) => {
            console.log("postBridgePayload result", result.data);
            const payload = result.data;
            //lets sign and send the payload
            setSignPayload(payload);
            setShowSendToBridgeModal(false);
            setIsLoading(false);
            setShowSignSendModal(true);
            
        }).catch((error) => {
            console.log("getAssertionPayload error", error);
            if (error.response) {
                setError(error.response.data.detail);
            } else {
                setError("Problem asserting claims");
            }
            setIsLoading(false);
        });
    };

    return (
        <Page xumm={xumm} runtime={runtime}>
            <div className="p-2">
                {error && <div className="p-2 error font-bold rounded bg-red-200 text-red-700">{error}</div>}
                <div className="flex flex-row justify-between">
                    <div className="text-3xl">Trustline Claimset</div>
                    <div className="flex flex-row">    
                        {parseInt(amountRemaining) > 0 && trustline?.issuerAddress == wallet?.classic_address && <div className="btn-common" onClick={()=>handleAssert(trustline)}>Assert Claims</div>}
                        
                        {/* DIST */}
                        {balance>0 && role?.roleType === RoleType.distributor && 
                        <>
                            <div className="btn-common" onClick={()=>setShowPayBuyerModal(true)} >
                                <span><FaArrowCircleUp className="text-lg text-pink-500 mr-1"/></span>
                                Send CRU SFTs
                            </div>
                            <div onClick={()=>handleRetire(trustline)} className="btn-common">
                                <span><AiFillFire className="text-lg text-pink-500"/></span> 
                                Retire CRU SFTs
                            </div>
                            {!trustline?.bridgeAccount && <div onClick={()=>setShowChooseBridgeModal(true)} className="btn-common">
                                <span><GiStoneBridge className="mb-2 text-lg text-pink-500"/></span> 
                                Choose Bridge Wallet                           
                            </div>}
                            <div onClick={()=>setShowSendToBridgeModal(true)} className="btn-common">
                                <span><GiStoneBridge className="mb-2 text-lg text-pink-500"/></span> 
                                Send CRUs To Bridge                          
                            </div>
                            <div onClick={()=>setShowChooseBuyerModal(true)} className="btn-common">
                                <span><FaUserPlus className="mb-2 text-lg text-pink-500"/></span> 
                                Add Buyer Wallet
                            </div>
                        </>}
                        
                        {/* BRIDGE */}
                        {role?.roleType === RoleType.bridge &&
                        <>
                            {!trustline?.bridgeAccount && <div onClick={()=>handleBridgeTrustline()} className="btn-common">
                                <span><GiStoneBridge className="mb-2 text-lg text-pink-500"/></span> 
                                Make Bridge Trustline                            
                            </div>}
                        </>}  

                        {/* BUYER */}
                        {role?.roleType === RoleType.buyer &&
                        <>                           
                            <div onClick={()=>handleRetire(trustline)} className="btn-common">
                                <span><AiFillFire className="text-lg text-pink-500"/></span> 
                                Retire CRU SFTs
                            </div>
                            {trustline?.bridgeAccount && 
                            <div onClick={()=>setShowSendToBridgeModal(true)} className="btn-common">
                                <span><GiStoneBridge className="mb-2 text-lg text-pink-500"/></span> 
                                Send CRUs to Bridge                           
                            </div>}
                        </>}                     
            
                    </div>
                </div>

                {/* BALANCE */}
                {trustline &&
                    <TrustlineBalanceInfo 
                        wallet={wallet}
                        trustline={trustline} 
                        amountRemaining={amountRemaining} 
                        balance={balance}/>         
                }

                {trustline?.vintages && <div className="font-mono">Vintage: {trustline?.vintages[0]}</div>}
                
                {role && <div className="font-mono">ROLE: {role.roleType}</div>}

                {/* wallets */}
                <div className="mb-2">
                    <TrustlineWallets trustline={trustline} />
                </div>             
                
                <div className="font-mono mb-2">Project: 
                    <span onClick={()=>navigate(`/project/${trustline?.grapheneProjectId}`)} 
                        className="ml-1 link-common">{trustline?.projectName}</span></div>
                {trustline && trustline?.txHash && <div className="font-mono">TX 
                    <span onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${trustline.txHash}`,'_blank')} className="link-common ml-2">{trustline.txHash}</span></div>}               

                
                {trustline &&
                <>
                    {trustline?.reserves && trustline?.reserves.length>0 && 
                        <ReservePills reserves={trustline.reserves}/>}

                    {trustline?.buyerAccounts && <>
                        <BuyersPills buyers={trustline.buyerAccounts} />                
                    </>}

                    {trustline?.carbonResourceUnits && trustline?.carbonResourceUnits.length>0 &&
                        <CRUPills crus={trustline.carbonResourceUnits} />}           
                    <div>
                        <BenefitClaimsPills benefitClaims={trustline?.benefitClaims} />
                    </div>
                    
                    {trustlineClaims && trustlineClaims.length>0 && <div>
                        <div className="text-2xl font-bold mt-2">Asserted Claims</div>
                        <PaidAssertedClaimsList claims={trustlineClaims}/>
                    </div>}              

                    <div>
                    <RecursiveHashedInfoViewer hashedInfo={trustline} showTitle={false}/></div>
                    <AssertClaimsModal 
                        assertionInfo={assertionInfo}
                        setAssertionInfo={setAssertionInfo}
                        conveyanceDocument={conveyanceDocument}
                        setConveyanceDocument={setConveyanceDocument}
                        showModal={showAssertModal} 
                        setShowModal={setShowAssertModal} 
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setTrustlineClaims={handleTrustlineClaims} 
                        benefitClaims={trustline.benefitClaims}/>

                    {/* choose the bridge wallet */}
                    <ChooseWalletModal
                        title="Choose Bridge Wallet"
                        wallets={wallets}
                        showModal={showChooseBridgeModal}
                        setShowModal={setShowChooseBridgeModal}
                        handler={handleSetBridgeWallet}/>
                    {/* choose the buyer wallet */}
                    <ChooseWalletModal
                        title="Choose Buyer Wallet"
                        wallets={wallets}
                        showModal={showChooseBuyerModal}
                        setShowModal={setShowChooseBuyerModal}
                        handler={handleAddBuyerWallet}/>

                    <SendCRUsBridgeModal
                        bridgeWalletId={bridgeWalletId}
                        showModal={showSendToBridgeModal}
                        setShowModal={setShowSendToBridgeModal} 
                        handler={handleSendToBridge}/>
                    <RetireModal 
                        showModal={showRetireModal} 
                        setShowModal={setShowRetireModal} 
                        setRetirePayload={setSignPayload}
                        setShowSignSendModal={setShowSignSendModal}
                        trustlineClaimsetId={trustlineClaimsetId}/>
                    <SignSendModal 
                        setTxResult={setTxResult}
                        showModal={showSignSendModal} 
                        setShowModal={setShowSignSendModal}
                        payload={signPayload}/>
                    <PayWalletModal
                        wallets={wallets}
                        showModal={showPayBuyerModal}
                        setShowModal={setShowPayBuyerModal}
                        handler={handlePayBuyerWallet}/>
                </>}
            </div>

        </Page>
    );
};