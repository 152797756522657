import { Page } from "../components/Page";
import { HeroImage, HomeList } from "../components/HomeComponents";

export const Home = ({
    xumm=null,
    runtime=null}) => {
    return (
        <Page xumm={xumm} runtime={runtime}>
            <HeroImage />
            <HomeList />
        </Page>
    );
};