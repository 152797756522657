import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import jwt from "jsonwebtoken";
import { saveAs } from "file-saver";
import Dropdown from "react-dropdown";

import {
  FaTrashAlt,
  FaFileDownload,
  FaExchangeAlt,
} from "react-icons/fa";

import { Page } from "../components/Page";
import { Spinner } from "../components/Base";
import { useAuthStore } from "../store";
import { GrapheneService } from "../services/GrapheneService";
import { ProjectStatusPill, ProjectStatus } from "../components/ProjectBase";
import { JsonFileReaderComponent } from "../components/Base";

import { RoleType, roleTypeName, determineRoleType } from "../utils";

import {apiConfig} from "../env";




export const ProjectUploadModal = ({ setProjects, projects }) => {
  const [showModal, setShowModal] = useState(false);
  const [project, setProject] = useState(null);
  const [role, setRole] = useState(null);

  const [projectRoles, setProjectRoles] = useState([
    { value: RoleType.projectOwner, label: "Project Owner" },
    { value: RoleType.distributor, label: "Project Trustline Distributor" },
  ]);

  useEffect(() => {
    console.log("project", project);
  }, [project]);

  const cancelUpload = () => {
    setProject(null);
    setRole(null);
    setShowModal(false);
  };

  const handleSelect = (option) => {
    console.log("You selected ", option);
    setRole(option.value);
  };

  const handleAddToStore = () => {
    console.log("handleAddToStore");

    let jwt_stored = useAuthStore.getState().jwt;
    let classic_address = jwt.decode(jwt_stored).sub;

    let nProject = {
      id: project.id, //use the id from the project file
      epp: project,
      addressRole: { 
        classic_address: classic_address, 
        roleType: role }
    };

    console.log("nProject", nProject);

    GrapheneService.postProject(nProject).then((res) => {
      console.log("postProject", res.data);
      const nProjects = [...projects, res.data];
      nProjects.sort((a, b) => (a.id > b.id ? 1 : -1));
      setProjects(nProjects);
      setShowModal(false);
    });
  };

  return (
    <>
      <div className="flex flex-row justify-end w-fit">
        <div
          className="btn-common"
          onClick={() => setShowModal(true)}
        >Import Project File</div>
      </div>

      {showModal && (
        <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
          <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
            <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
              <div className="modal-dialog">
                <div className="modal-header font-bold mb-2">
                  Upload a EP Project File
                </div>
                <div className="modal-content flex flex-col">
                  <JsonFileReaderComponent setJson={setProject} />

                  {project && project.name && (
                    <div className="flex flex-col w-full">
                      <div className="mb-3">
                        <strong>Project Name:</strong> {project.name}
                      </div>
                      <div className="font-bold">Select Wallet Role</div>
                      <div className="mix-y-[120]">
                        <Dropdown
                          options={projectRoles}
                          onChange={handleSelect}
                          placeholder="Select a role"
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex flex-row justify-end">
                    {project && role !== null && (
                      <div
                        onClick={() => handleAddToStore()}
                        className="btn-common m-1"
                      >
                        Add Project
                      </div>
                    )}
                    <div
                      onClick={() => cancelUpload()}
                      className="btn-common m-1"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const FakeProjectFormModal = ({ setProjects, projects }) => {
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const [projectRoles, setProjectRoles] = useState([
    { value: RoleType.projectOwner, label: "Project Owner" },
    { value: RoleType.distributor, label: "Project Trustline Distributor" },
  ]);

  const [formState, setFormState] = useState({
    name: "",
    description: "",
    domain: "",
    externalid: "",
    role: "none",
  });

  const handleInputChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formState);
    GrapheneService.makeFakeProject(formState).then((res) => {
      console.log("makeFakeProject", res.data, projects);
      // handleSaveProject(res.data);
      const n_projects = [...projects, res.data];
      n_projects.sort((a, b) => (a.id > b.id ? 1 : -1));
      setProjects(n_projects);
      setShowModal(false);
    }).catch((e) => {
      console.log("postFakeProject", e);
      if(e.message === "Network Error") {
          setError("There was a problem connecting to the network.");
      } else {
          console.log(e.message, e.response.data.message);
          setError(e.message);
          if(e.response.data) setError(e.response.data.message);
      }
    });
  };

  return (
    <>
      <div className="flex flex-row justify-end w-fit">
        <div
          className="btn-common"
          onClick={() => setShowModal(true)}
        >
          Generate Fake Project
        </div>
      </div>

      {showModal && (
        <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
          <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
            <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
              {error && (
                <div className="error rounded bg-red-200 text-red-700 p-1">
                  {error}
                </div>
              )}

              <div className="text-lg">Create A Fake EPP Project</div>
              <form onSubmit={handleSubmit} className="flex flex-col p-1">
                {error && <p className="error">{error}</p>}
                <label htmlFor="name">EPP Project Name</label>
                <input
                  className="text-sm rounded border-2 border-slate-300 p-2"
                  type="text"
                  name="name"
                  value={formState.name}
                  placeholder="Unique name for the project"
                  onChange={(event) => handleInputChange(event)}
                />
                <label className="text-gray-600">Description</label>
                <textarea
                  name="description"
                  value={formState.description}
                  onChange={handleInputChange}
                  className="text-sm w-full h-32 px-4 py-3 border-2 border-gray-300
                        rounded outline-none  focus:border-gray-400"
                  placeholder="A few sentences about the project"
                ></textarea>
                <label htmlFor="domain">Project Domain</label>
                <input
                  className="text-sm rounded border-2 border-slate-300 p-2"
                  type="text"
                  name="domain"
                  value={formState.domain}
                  placeholder="The project domain for trustlines"
                  onChange={(event) => handleInputChange(event)}
                />
                <label htmlFor="domain">External ID</label>
                <input
                  className="text-sm rounded border-2 border-slate-300 p-2"
                  type="text"
                  name="externalid"
                  value={formState.externalid}
                  placeholder="A unique ID for the project from an external system"
                  onChange={(event) => handleInputChange(event)}
                />
                <div className="flex flex-row justify-end w-full">
                  <button
                    className="btn-cancel"
                    type="cancel"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button className="btn-common" type="submit">
                    Create Fake Project
                  </button>
                </div>


              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ProjectsList = ({ projects, removeProject, wallet }) => {
  const navigate = useNavigate();

  const [uAddress, setUAddress] = useState();

  useEffect(() => {
    let jwt_stored = useAuthStore.getState().jwt;
    let classic_address = jwt.decode(jwt_stored).sub;
    setUAddress(classic_address);

  }, []);

  const handleSaveProject = (project) => {
    console.log("handleSaveProject", project);
    const blob = new Blob([JSON.stringify(project)], {
      type: "application/json",
    });
    saveAs(blob, `project_${project.id}.json`);
  };

  const findWalletRoleName = (project_id,wallet_id,project_wallets) => {
    // return projects.find((p) => p.projectId === projectId).role;
    const roleName = project_wallets.find((pw) => {
      console.log("findWalletRoleName", pw, project_id, wallet_id);
      return pw.wallet_id === wallet_id && pw.project_id === project_id
    })?.role;
    return roleName ? roleName : "Not Assigned";
  };


  // {
  //   "id": 1,
  //   "user_id": 18,
  //   "project_name": "The Hungry Winds",
  //   "project_description": "xsxsxsxs",
  //   "external_id": "f9f9574f",
  //   "country": "BG",
  //   "projectScale": "MEDIUM",
  //   "projectId": "930368b7-30a0-4ba2-b432-4ea8063db4ba",
  //   "region": "NORTH_AMERICA",
  //   "firstYearIssuance": 2018,
  //   "created_at": "2023-05-01 18:54:35.358995",
  //   "updated_at": "2023-05-01 18:54:35.358999"
  // }
  return (
    <div className="flex flex-col">
      <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
        <thead>
          <tr>
            <th>Status</th>
            {/* <th>ID</th> */}
            <th>Name</th>
            <th>Description</th>
            <th>Domain</th>
            <th>EID</th>
            <th>Role</th>
            <th scope="col" className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {wallet && projects.map((item, index) => (
            <tr key={index} className="mb-1">
              <td>
                <ProjectStatusPill status={item.status} />
              </td>
              {/* <td className="font-mono">{item.id}</td> */}
              <td>
                <div
                  className="text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300"
                  onClick={() => navigate(`/project/${item.id}`)}
                >
                  {item?.project_name}
                </div>
              </td>
              <td className="break-words max-w-[300]">
                {item?.project_description}
              </td>
              <td>
                <div className="break-words max-w-[140] font-mono text-xs font-bold">
                  {item?.domain}
                </div>
              </td>
              <td>{item?.external_id}</td>
              <td className="flex flex-wrap">
                {wallet && findWalletRoleName(item.id, wallet.id, item.project_wallets)}
                {/* {uAddress && roleTypeName(determineRoleType(uAddress, item.addressRoles))} */}
              </td>
              <td scope="col" className="">
                  <div className="flex flex-row items-center justify-center">
                    <div
                      onClick={() => { handleSaveProject(item); }}
                      className="text-gray-200 hover:text-yellow-300 m-1"
                    >
                      <FaFileDownload />
                    </div>
                    {item?.nft_tx?.tx_hash && <div
                      onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${item?.nft_tx?.tx_hash}`,'_blank')}
                      className="text-gray-200 hover:text-yellow-300 m-1"
                    >
                      <FaExchangeAlt />
                  </div>}
                  {item.status == ProjectStatus.CREATED &&<div
                    onClick={() => { removeProject(item); }}
                    className="text-gray-200 hover:text-yellow-300 m-1"
                  >
                     <FaTrashAlt />
                  </div>}

                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const Projects = ({
  xumm=null,
  runtime=null}) => {
  const [error, setError] = useState();
  const [projects, setProjects] = useState([]);
  const [wallet, setWallet] = useState();

  useEffect(() => {
      GrapheneService.getProjects().then((res) => {
        console.log("getProjects res", res);
        let n_projects = res.data;
        // n_projects.sort((a, b) => (a.id > b.id ? 1 : -1));
        setProjects(n_projects);
      }).catch((e) => {
        console.log("getProjects err", e);
  
        if(e.message === "Network Error") {
          setError("There was a problem connecting to the network.");
        } else {
            console.log(e.message, e.response.data.message);
            setError(e.message);
            if(e.response.data) setError(e.response.data.message);
        }

      });
  }, []);

  useEffect(() => {
    GrapheneService.getUser().then((res) => {
      console.log("getUser res", res.data);
      const user = res.data;
      GrapheneService.getWallet()
      .then((res) => {
        console.log("getWalletByAddress res", res.data);
        // filter the wallet on the user default wallet
        setWallet(res.data.filter((w) => w.id === user.default_wallet_id)[0]);
      });
    });
  }, [projects]);


  const removeProject = (project) => {
    try {
      console.log("removeProject", project);
      // let jwt_stored = useAuthStore.getState().jwt;
      // let classic_address = jwt.decode(jwt_stored).sub;
      GrapheneService.deleteProject(project.id).then((res) => {
        console.log("deleteProject res", res);
        let n_projects = [...projects];
        n_projects.sort((a, b) => (a.id > b.id ? 1 : -1));
        setProjects(n_projects.filter((item) => item.id !== project.id));
      });
    } catch (e) {
      
      if(e.message === "Network Error") {
        setError("There was a problem connecting to the network.");
      } else {
          console.log(e.message, e.response.data.message);
          setError(e.message);
          if(e.response.data) setError(e.response.data.message);
      }
    }
  };

  return (
    <Page xumm={xumm} runtime={runtime}>
      <div className="p-2 flex flex-col justify-start">
        {error && (
          <div className="rounded bg-red-200 text-red-700 p-1 font-bold">{error}</div>
        )}

        <div className="flex flex-row justify-between">

          <div className="text-3xl">Projects</div>
          <div className="flex flex-row justify-end">
            <div className="flex flex-row">
              <FakeProjectFormModal setProjects={setProjects} projects={projects}/>
            </div>
          </div>

        </div>

        {projects && projects.length > 0 ? (
          <ProjectsList projects={projects} removeProject={removeProject} wallet={wallet} />
        ) : (
          <>No Projects</>
        )}
      </div>
    </Page>
  );
};
