import { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Page } from "../components/Page";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import {FormInputField, ErrorList, Spinner} from "../components/Base";
import imgIcon64 from "../assets/img/graphen_icon2_128.png";
import { GrapheneService } from "../services/GrapheneService";
import { useAuthStore } from "../store";


const resetPasswordFields=[
    {
        labelText:"New Password",
        labelFor:"newPassword",
        id:"newPassword",
        name:"newPassword",
        type:"password",
        autoComplete:"new-password",
        isRequired:true,
        placeholder:"New Password"   
    },
    {
        labelText:"New Password Again",
        labelFor:"newPassword2",
        id:"newPassword2",
        name:"newPassword2",
        type:"password",
        isRequired:true,
        placeholder:"Repeat New Password"   
    }
]


const sendResetEmailFields=[
    {
        labelText:"Email address",
        labelFor:"email-address",
        id:"email-address",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"Email address"   
    },
]

const ResetPasswordForm = ({sendResetEmailFields, submitText='Sent Reset Email', code=null}) => {

    const navigate = useNavigate();

    const fields=sendResetEmailFields;
    let fieldsState = {};
    fields.forEach(field=>fieldsState[field.id]='');

    const [resetPasswordState,setResetPasswordState]=useState({});
    const [errors,setErrors]=useState([]);
    const [successMessage,setSuccessMessage]=useState(null);


    const handleChange=(e)=>{
        console.log("handleChange: ",e.target.id,e.target.value);
        setResetPasswordState({...resetPasswordState,[e.target.id]:e.target.value})
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        if (code) {
            handleResetPassword();
        } else {
            handleSendEmail();
        }
        
    };

    const validatePassword = (password, errors) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        if (!passwordRegex.test(password)) {
        //   setErrors([...errors,"Password is invalid, Must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number"]);
            errors.push("Password is invalid, Must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number");
        }      
    };

    const handleResetPassword=()=>{
        const errors=[];
        if(!resetPasswordState["newPassword"]){
            errors.push("Please enter a new password");
        }
        if(!resetPasswordState["newPassword2"]){
            errors.push("Please enter the new password again");
        }
        if(resetPasswordState["newPassword"]!==resetPasswordState["newPassword2"]){
            errors.push("Passwords do not match");
        }
        validatePassword(resetPasswordState["newPassword"], errors);
        if(errors.length>0){
            setErrors(errors);
            return;
        } else {
            GrapheneService.updatePassword(resetPasswordState["newPassword"],code)
            .then(r=>{
                console.log("updatePassword response: ",r);
                setSuccessMessage("You have successfully updated your password. You will need to login again.");
                setTimeout(()=>{
                    setErrors([]);
                    setSuccessMessage(null);
                    navigate("/login");
                },3000); 
            })
            .catch(e=>{
                console.log(e);
                if(e.response && e.response.data) {
                    console.log("e.response.data: ",e.response.data);
                    setErrors([e.response.data.detail]);
                } else if (e.message) {
                    setErrors([e.message]);
                } else {
                    setErrors(["Error creating user"]);
                }
            });
        }
    };

    const handleSendEmail=()=>{

        const errors=[];
        if(!resetPasswordState["email-address"]){
            errors.push("Email address is required");
        }
        // if(!resetPasswordState["password"]){
        //     errors.push("Password is required");
        // }
        if(errors.length>0){
            setErrors(errors);
            return;
        } else {
            setErrors([]);
            
            GrapheneService.resetPassword(resetPasswordState["email-address"])
            .then(r=>{
                console.log("resetPassword response: ",r);
                setSuccessMessage("If you have an account with the site please look in your inbox for reset password instructions.")
            })
            .catch(e=>{
                console.log(e);
                if(e.response && e.response.data) {
                    console.log("e.response.data: ",e.response.data);
                    setErrors([e.response.data.detail]);
                } else if (e.message) {
                    setErrors([e.message]);
                } else {
                    setErrors(["Error creating user"]);
                }
            });

        }    

    };


    return(
        <form className="mt-1">
            {errors && errors.length>0 && 
            <div className="m-2 alert-danger text-center">
                <ErrorList errors={errors}/>
            </div>}
            {successMessage ?
            <div className="m-2 alert-success text-center flex flex-col">
                <div>{successMessage}</div> 
            </div>:
            <>          
                <div>
                {
                    fields.map(field=>
                            <FormInputField
                                key={field.id}
                                handleChange={handleChange}
                                // value={resetPasswordState[field.id]}
                                labelText={field.labelText}
                                labelFor={field.labelFor}
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                isRequired={field.isRequired}
                                placeholder={field.placeholder}
                                customClass="mb-1"
                        />
                    
                    )
                }
                </div>
                <div className="flex flex-row items-center justify-between">
                    <div onClick={(e)=>handleSubmit(e)} className="btn-common flex w-[300]">
                        {submitText}
                    </div>
                </div>
            </>}
      </form>
    )
}

export const ResetPassword = ({
    xumm=null,
    runtime=null}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const status = searchParams.get("status");

    useEffect(()=>{
        if(status && status==="RESET_PENDING"){
            //email-address
        }
    },[code,status]);
    
    return (
        <div className="flex flex-col mt-3">
            <div className="w-full text-3xl font-semibold flex flex-row justify-center p-2 items-center text-center">
                <img src={imgIcon64} alt="graphene" className="inline-block h-10 align-middle mr-2" />
                <span className="font-heading">graphene</span><span className="font-heading text-cyan-200">impact</span>
            </div>
            {code && status && status==="RESET_PENDING" ?
            <div className="flex flex-col justify-center mt-4 w-full items-center">
                <div className="text-3xl text-center">Reset Password</div>
                <div className="flex flex-col">
                    Enter your new password below.
                </div>
                <div className="italic text-gray-400">Passwords should...</div>
                <ul className="list-decimal max-w-[300] break-words ml-10">
                    <li>Be at least 8 characters long.</li>
                    <li>Contain at least one lowercase letter.</li>
                    <li>Contain at least one uppercase letter.</li>
                    <li>Contain at least one number.</li>
                    <li>Contain at least one special character (e.g., !@#$%^&*).</li>
                </ul>

                <ResetPasswordForm
                    code={code} 
                    sendResetEmailFields={resetPasswordFields} 
                    submitText="Update Password"/> 
            </div>
            :
            <div className="flex flex-row justify-center mt-4">
                <div className="flex flex-col w-[300]">

                    <div className="text-3xl w-100 text-center">Reset Password</div>
                    <div className="w-100 text-center">If you are a registered user an email will be sent to you with instructions.</div>
                    <ResetPasswordForm sendResetEmailFields={sendResetEmailFields}/> 
                    <div className="w-100 text-center">Don't have an account yet? <span onClick={()=>navigate("/signup")} className="ml-3 text-yellow-500 link-common">Signup</span></div>

                    <div className="w-100 text-center">Not Ready? <span onClick={()=>navigate("/")} className="ml-3 text-yellow-500 link-common">Goto Home</span></div>
                </div>
            </div>}
        </div>
    );
};