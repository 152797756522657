import { useEffect, useState } from "react";

import yaml from 'json-to-pretty-yaml';



/**
 * simple component to display of hashed data
 * @param {*} param0 
 * @returns 
 */
export const RecursiveHashedInfoViewer = ({hashedInfo, title="Hashed Data", showTitle=true, isNested=true}) => {

    const [prettyYaml, setPrettyYaml] = useState(yaml.stringify(hashedInfo));


    const  getType = (element) => {
        const typeString = Object.prototype.toString.call(element);
        return typeString.slice(8, -1);
    }

    const renderAttribute = (hashedInfoItem, key="") => {

        const type = getType(hashedInfoItem);
        // console.log("type", type, "key", key, type === 'Object');
        
        let rVal = "";
        switch (type) {
            case 'Array':
                // get the type of the first element
                const firstElement = hashedInfoItem[0];
                const firstElementType = getType(firstElement);
                if (firstElementType === 'Object') {
                    // const keys = Object.keys(firstElement);
                    rVal = 
                        <div>{hashedInfoItem.map((arrayItem, index) => (
                        <div className="max-w-[300] md:max-w-[800] flex flex-row w-screen border-dashed border-2 border-cyan-500 rounded-lg m-1" key={index}>
                            <RecursiveHashedInfoViewer hashedInfo={arrayItem} showTitle={false}/>
                        </div>
                        ))}</div>

                } else {
                     rVal = <div className="flex flex-row text-sm font-mono font-bold break-words">{JSON.stringify(hashedInfoItem)}</div> ;
                }
                break;
            case 'String':
            case 'Number':
            case 'Boolean':
            case 'Date':
                rVal = <div className="flex flex-row text-sm font-mono font-bold break-words max-w-[300] md:max-w-[800]"><span className="md:text-lg">{hashedInfoItem}</span></div> 
                break;
            case 'Object':
                rVal = <div className="flex flex-row w-full"><RecursiveHashedInfoViewer hashedInfo={hashedInfoItem} title={key} showTitle={false}/></div>;
                break;
            default:
                rVal = <div className="text-xs font-mono font-bold break-words">{JSON.stringify(hashedInfoItem)}</div>;
                // rVal = <></>
                break;
        }


        return rVal;
    };


    const renderAttributes = (hashedInfo, isNested=true) => {
        let useFlexClass = isNested? "flex flex-row w-full" : "flex flex-wrap";
        if (hashedInfo) {
            const keys = Object.keys(hashedInfo);
            if (keys.length === 0) {
                return <div className="flex">No account data</div>;
            } else {
                return keys.map((key, index) => (
                    <div key={index} className={useFlexClass}>
                        {hashedInfo[key] ? 
                        <div className="flex flex-wrap text-yellow-200 bg-slate-600 m-1 rounded p-1 break-words">
                            <div className="flex flex-col">
                                <div className="text-xs font-bold text-slate-200 m-1">{key}</div> 
                                <div className="m-1">{renderAttribute(hashedInfo[key], key)}</div>
                            </div>
                        </div>:
                        <></>}
                    </div>
                ));
            }
        }
    };

  
    return (
        <>
            {hashedInfo && 
             <div className="flex flex-col">       
                {showTitle && <div className="flex text-heading text-2xl">{title}</div>}
                <div className="flex flex-wrap bg-slate-700 p-2">
                    <pre className="text-sm text-yellow-200">{prettyYaml}</pre>
                </div>
                

            </div>}
        </>
    );
  };
  