import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GrapheneService } from "../services/GrapheneService";
import { Page } from "../components/Page";
import {fromHexToCurrency, formatDate} from "../utils";
import {apiConfig} from "../env";
import {GrCertificate} from "react-icons/gr";
import {AiFillBank} from "react-icons/ai";
import {TbCertificate} from "react-icons/tb";

export const AssertedClaimsList = ({assertions}) => {

    const navigate = useNavigate();

    const makeClaimDocumentEndpoint = (ipfsHash) => {
        let pinataGateway = apiConfig().pinataGateway;
        return `${pinataGateway}/${ipfsHash}`;
    };

   
    return (
        <div className="flex flex-col">
        {assertions.length === 0 ? 
            <div className="text-left text-lg font-bold text-gray-400">No Assertions</div>:
            <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Assertion ID</th>
                    <th>Claimset ID</th>
                    <th>Vintage</th>
                    <th>Benefit Names</th>
                    <th>Amount</th>
                    <th scope="col" className="text-center">Actions</th> 
                </tr>
                </thead>
                <tbody>
                {assertions.map((item, index) => (
                    <tr key={index} className="mb-1">                    
                        <td className="text-sm font-bold">
                            {item?.payment?.datetime && formatDate(item?.payment?.datetime) }
                        </td>                 
                        <td>{item.id}</td>
                        {item?.grapheneClaimsetId ? 
                            <td onClick={()=>navigate(`/trustline/${item.grapheneClaimsetId}`)} 
                                className="text-sm font-bold link-common">{item.claimsetId}</td> :
                            <td className="text-sm font-bold">{item.claimsetId}</td>
                        }
                        <td>
                            {item?.conveyance?.vintage}
                        </td>
                        <td className="flex flex-wrap">
                            {item.benefitClaimsAsserted.map((benefit, index) => (
                                <div key={index} className="m-1 p-1 bg-cyan-600
                                    font-bold text-white rounded text-xs">{benefit.name}</div>
                            ))} 
                        </td>
                        <td className="text-sm font-bold">
                            <div className="flex">
                                <div className="text-lg font-mono font-bold text-yellow-200">
                                    {item?.payment.amount.value}
                                    {' '}{fromHexToCurrency(item.payment.amount.currency)}
                                    </div>
                            </div>
                        </td>
                        <td className="flex flex-row justify-end">
                            <div onClick={()=>window.open(makeClaimDocumentEndpoint(item?.conveyance?.ipfsHash),'_blank')} className="text-gray-200 hover:text-cyan-400 text-2xl flex hover:underline hover:cursor-pointer">
                                <TbCertificate className="ml-1 mr-1"/> <span className="font-mono text-xs hidden md:block"></span>
                            </div>

                            <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${item?.payment.txHash}`,'_blank')} className="text-gray-200 text-2xl flex hover:underline hover:cursor-pointer hover:text-cyan-400 tooltip">
                                <AiFillBank className="ml-1 mr-1"/> <span className="font-mono text-xs hidden md:block"></span>
                            </div>


                        </td>
                    </tr>
                ))}
                </tbody>
            </table>}
        </div>
    );




};

export const Claims = ({
    xumm=null,
    runtime=null}) => {
    const [error, setError] = useState(null);
    const [assertions, setAssertions] = useState([]);

    // we should be able to get the projects and the claims from the API
    // each claim should have a list of assertions
    useEffect(() => {
        let n_assertions = [];
        GrapheneService.getClaimsetTrustlinesAssertions()
        .then((res) => {
            console.log("getClaimsetTrustlinesAssertions", res.data);
            setAssertions(res.data);
        })
        .catch((e) => {
            console.log("error", e);
            setError(e.data);
        });

    }, []);


    return (
        <Page xumm={xumm} runtime={runtime}>
            <div className="p-2">
                {error && <div className="error rounded bg-red-200 text-red-700">{error}</div>}
                <div className="text-3xl">Asserted Claims</div>
                <AssertedClaimsList assertions={assertions} />
            </div>
        </Page>
    );
};