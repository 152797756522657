import Axios from 'axios';



import { apiConfig, webBaseUrl } from "../env";
import { useAuthStore } from "../store";

Axios.defaults.withCredentials = false;
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';

/**
 * This could benefit from separating the API calls into separate files
 * based on the domain model. For example, a file for Project, a file for
 * Trustline, etc.
 * 
 * @typedef {Object} ProjectInfo
 */
export const GrapheneService = {
    async getInfo () {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/info`);
    },
    async auth (email,password) {    
        const params = new URLSearchParams();
        params.append('email', email);
        params.append('password', password); 
        return Axios.post(`${apiConfig().apiBaseUrl}/auth/token`, params)
    },
    async resetPassword (email) {
        const params = new URLSearchParams();
        params.append('email', email);
        return Axios.post(`${apiConfig().apiBaseUrl}/auth/reset/email`, params)
    },
    async updatePassword (newPassword, updateCode) {
        const params = new URLSearchParams();
        params.append('newPassword', newPassword);
        params.append('updateCode', updateCode);
        return Axios.post(`${apiConfig().apiBaseUrl}/auth/reset/update`, params)
    },
    async getWallet (
        accountInfo=true,  
        accountLines=true,
        accountNfts=true,
        accountTx=true) {
        return await Axios.get(`${apiConfig().apiBaseUrl}/wallet`, 
            { params: { accountInfo, accountLines, accountTx, accountNfts } });
    },
    async getWalletById (
        wallet_id,
        accountInfo=true,  
        accountLines=true,
        accountNfts=true,
        accountTx=true) {
        return await Axios.get(`${apiConfig().apiBaseUrl}/wallet/${wallet_id}`, 
            { params: { accountInfo, accountLines, accountTx, accountNfts } });
    },
    async createUser (email, password) {
        // used at signup
        //redirect_uri: "https://graphene.dev"
        const params = new URLSearchParams();
        params.append('redirect_uri', `${apiConfig().webBaseUrl}/login?status=VERIFIED`);
        params.append('email', email);
        params.append('password', password);
        return await Axios.post(`${apiConfig().apiBaseUrl}/user`, params);
    },
    async updateUser (email, wallet_id=null, password=null) {
        return await Axios.put(`${apiConfig().apiBaseUrl}/user`, {email, wallet_id});
    },
    async getUser () {
        return await Axios.get(`${apiConfig().apiBaseUrl}/user`);
    },
    async createWallet(walletInfo) {
        return await Axios.post(`${apiConfig().apiBaseUrl}/wallet`, walletInfo);
    },
    async updateWallet(walletInfo) {
        return await Axios.put(`${apiConfig().apiBaseUrl}/wallet`, walletInfo);
    },
    async getWallets () {
        return await Axios.get(`${apiConfig().apiBaseUrl}/wallet`);
    },
    async makeFakeProject (projectInfo) {
        return await Axios.post(`${apiConfig().apiBaseUrl}/project/fake`, projectInfo);
    },
    async postPinProjectIPFS (project, role, wallet) {
        const projectRole = {
            "classic_address": wallet.classic_address,
            "roleType": role,
        }

        let projectInfo = {
            "id": project.id,
            "status": project.status,
            "epp": project.epp,
            "addressRoles": [projectRole],
        }

        return await Axios.post(`${apiConfig().apiBaseUrl}/project/pin`, projectInfo);
    },
    async mintProjectNFT (project) {
        return await Axios.post(`${apiConfig().apiBaseUrl}/project/mint`, project);
    },
    async putIssuerAccountset (domain) {
        return await Axios.put(`${apiConfig().apiBaseUrl}/tlcs/issuer/accountset`, {domain});
    },
    async putDistrubutorAccountset () {
        return await Axios.put(`${apiConfig().apiBaseUrl}/tlcs/distributor/accountset`, {});
    },
    async createClaimsetTrustline (trustlineInfo) {
        return await Axios.post(`${apiConfig().apiBaseUrl}/tlcs`, trustlineInfo);
    },
    async getClaimsetTrustline (trustlineId) {
        return await Axios.get(`${apiConfig().apiBaseUrl}/tlcs/${trustlineId}`);
    },
    async getClaimsetTrustlines () {
        return await Axios.get(`${apiConfig().apiBaseUrl}/tlcs`);
    },
    async getClaimsetTrustlinesAssertions () {
        return await Axios.get(`${apiConfig().apiBaseUrl}/tlcsa`);
    },
    async getAddressProjectNft(issuerAddress, projectId) {
        return await Axios.get(`${apiConfig().apiBaseUrl}/project/nft/${issuerAddress}/${projectId}`);
    },
    async getTrustlinesForProject(projectId) {
        return await Axios.get(`${apiConfig().apiBaseUrl}/tlcs/project/${projectId}`);
    },
    async uploadConveyanceDocument(trustlineId, documentFile) {
        let formData = new FormData();
        formData.append('file', documentFile);
        return await Axios.post(`${apiConfig().apiBaseUrl}/tlcsa/${trustlineId}/document`, formData);
    },
    async uploadTonnageTableDocument(documentFile) {
        let formData = new FormData();
        formData.append('file', documentFile);
        return await Axios.post(`${apiConfig().apiBaseUrl}/tlcs/tids`, formData);
    },


    async assertClaimsForTrustline(trustlineClaimId, benefitClaimList) {
        // TrustlineClaimsetAssertionRequestSchema
        // POST /tlcsa/{claimsetId}
        let trustlineClaimsetAssertionRequest = {
            "benefitClaimsAsserted": benefitClaimList,
            "claimSetId": trustlineClaimId
        };
        return await Axios.post(`${apiConfig().apiBaseUrl}/tlcsa/${trustlineClaimId}`,trustlineClaimsetAssertionRequest);
    },
    async getProject(projectId) {
        return await Axios.get(`${apiConfig().apiBaseUrl}/project/${projectId}`);
    },
    async importMintedProject(issuerAddress, projectId) {

        // /project/blockchain/{issuerAddress}/{projectId}
        return await Axios.get(`${apiConfig().apiBaseUrl}/project/import/${issuerAddress}/${projectId}`);
    },
    async getProjects() {
        return await Axios.get(`${apiConfig().apiBaseUrl}/project`);
    },
    async postProject(project) {
        return await Axios.post(`${apiConfig().apiBaseUrl}/project`, project);
    },
    async updateProject(project, wallet, role) {


        // get the address roles from the project_wallets 
        // and add the new one using a map function
        const addressRoles = project.project_wallets.map((pw) => {
            return {
                "wallet_id": pw.id,
                "roleType": pw.role,
            }
        });

        const projectRole = {
            "wallet_id": wallet.id,
            "roleType": role,
        }

        let projectInfo = {
            "id": project.id,
            "status": project.status,
            "epp": project.epp,
            "addressRoles": [...addressRoles,projectRole],
        }        
        return await Axios.put(`${apiConfig().apiBaseUrl}/project`, projectInfo);
    },
    async addProjectRole(projectId, roleName, wallet_id=null  ) {
        let projectRole = {
            "roleType": roleName,
        }
        let addRoleInfo = {
            "project_id": projectId,
            "projectRole": projectRole,
            "wallet_id": wallet_id,
        }
        return await Axios.post(`${apiConfig().apiBaseUrl}/project/role`, addRoleInfo);
    },
    async deleteProject(projectId) {
        return await Axios.delete(`${apiConfig().apiBaseUrl}/project/${projectId}`);
    },
    async getTransactions() {
        return await Axios.get(`${apiConfig().apiBaseUrl}/transaction`);
    },
    // {
    //     "amount":2,
    //     "grapheneTlcsId":3,
    //     "bridgeType":"THALLO",
    //     "project":{
    //       "name": "awesome project",
    //       "location": "str",
    //       "description": "str",
    //       "publicUrl": "str",
    //       "grapheneTlcsId": "str",
    //       "type": "CO2",
    //       "sdgs": ["e2b8ac58-b685-4c10-882e-3be14b78631"]
    //     },
    //     "vintage":{
    //       "year": 2012
    //     }
    //   }
    // async bridgeCRUs(amount, grapheneTlcsId, bridgeType, vintage) {
    //     let crubridgeInfo = {
    //         "amount": amount,
    //         "grapheneTlcsId": grapheneTlcsId,
    //         "bridgeType": bridgeType,
    //         "vintage": vintage,
    //     }
    //     return await Axios.post(`${apiConfig().apiBaseUrl}/tlcs/bridge`, crubridgeInfo);
    // },
};

