import React, {useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import Axios from "axios";

// import { LoginModal } from "./LoginModal";
import { useAuthStore } from "../store";
import { GrapheneService } from "../services/GrapheneService";


import imgIcon64 from "../assets/img/centigrade_icon2_128.png";

export const Footer = () => {
    const [info, setInfo] = useState({});

    useEffect(() => {
        GrapheneService.getInfo().then((res) => {
            setInfo(res.data);
        });
    }, []);

    return (
        <>
            <footer className="h-fit min-h-[100] bg-black text-white flex 
                flex-row justify-between p-2">
                <div className="flex flex-col mb-8">
                    <div className="text-lg font-semibold flex flex-row justify-start p-2 items-center">
                        <img src={imgIcon64} alt="graphene" className="mr-2 inline-block h-6 align-middle" />
                        {/* <span className="font-heading">graphene</span><span className="font-heading text-cyan-200">impact</span> */}
                    </div>
                    <div>Using the XRP Blockchain to track carbon offset projects.</div>
                    {info && <div>API v{info.version} {info.network}</div>}
                </div>
                <div></div>

            </footer>  
        </>
    );
};