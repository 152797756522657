import Axios from 'axios';
import jwt from 'jsonwebtoken';
import { apiConfig } from "../env";
import { useAuthStore } from "../store";

Axios.defaults.withCredentials = false;
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';


export const XrplSetFlags = 
{
    "asfAccountTxnID": {
      "Decimal_Value": 5,
      "Corresponding_Ledger_Flag": null,
      "Description": "Track the ID of this account's most recent transaction. Required for AccountTxnID"
    },
    "asfAuthorizedNFTokenMinter": {
      "Decimal_Value": 10,
      "Corresponding_Ledger_Flag": null,
      "Description": "Enable to allow another account to mint non-fungible tokens (NFTokens) on this account's behalf. Specify the authorized account in the NFTokenMinter field of the AccountRoot object. To remove an authorized minter, enable this flag and omit the NFTokenMinter field. (Added by the NonFungibleTokensV1_1 amendment.)"
    },
    "asfDefaultRipple": {
      "Decimal_Value": 8,
      "Corresponding_Ledger_Flag": "lsfDefaultRipple",
      "Description": "Enable rippling on this account's trust lines by default."
    },
    "asfDepositAuth": {
      "Decimal_Value": 9,
      "Corresponding_Ledger_Flag": "lsfDepositAuth",
      "Description": "Enable Deposit Authorization on this account. (Added by the DepositAuth amendment.)"
    },
    "asfDisableMaster": {
      "Decimal_Value": 4,
      "Corresponding_Ledger_Flag": "lsfDisableMaster",
      "Description": "Disallow use of the master key pair. Can only be enabled if the account has configured another way to sign transactions, such as a Regular Key or a Signer List."
    },
    "asfDisallowIncomingCheck": {
      "Decimal_Value": 13,
      "Corresponding_Ledger_Flag": "lsfDisallowIncomingCheck",
      "Description": "Block incoming Checks. (Requires the DisallowIncoming amendment )"
    },
    "asfDisallowIncomingNFTokenOffer": {
      "Decimal_Value": 12,
      "Corresponding_Ledger_Flag": "lsfDisallowIncomingNFTokenOffer",
      "Description": "Block incoming NFTokenOffers. (Requires the DisallowIncoming amendment )"
    },
    "asfDisallowIncomingPayChan": {
      "Decimal_Value": 14,
      "Corresponding_Ledger_Flag": "lsfDisallowIncomingPayChan",
      "Description": "Block incoming Payment Channels. (Requires the DisallowIncoming amendment )"
    },
    "asfDisallowIncomingTrustline": {
      "Decimal_Value": 15,
      "Corresponding_Ledger_Flag": "lsfDisallowIncomingTrustline",
      "Description": "Block incoming trust lines. (Requires the DisallowIncoming amendment )"
    },
    "asfDisallowXRP": {
      "Decimal_Value": 3,
      "Corresponding_Ledger_Flag": "lsfDisallowXRP",
      "Description": "XRP should not be sent to this account. (Advisory; not enforced by the XRP Ledger protocol.)"
    },
    "asfGlobalFreeze": {
      "Decimal_Value": 7,
      "Corresponding_Ledger_Flag": "lsfGlobalFreeze",
      "Description": "Freeze all assets issued by this account."
    },
    "asfNoFreeze": {
      "Decimal_Value": 6,
      "Corresponding_Ledger_Flag": "lsfNoFreeze",
      "Description": "Permanently give up the ability to freeze individual trust lines or disable Global Freeze. This flag can never be disabled after being enabled."
    },
    "asfRequireAuth": {
      "Decimal_Value": 2,
      "Corresponding_Ledger_Flag": "lsfRequireAuth",
      "Description": "Require authorization for users to hold balances issued by this address. Can only be enabled if the address has no trust lines connected to it."
    },
    "asfRequireDest": {
      "Decimal_Value": 1,
      "Corresponding_Ledger_Flag": "lsfRequireDestTag",
      "Description": "Require a destination tag to send transactions to this account."
    }
  };

  export const getSetFlagById = (id) => {
    for (const [key, value] of Object.entries(XrplSetFlags)) {
        if(value.Decimal_Value === id) {
            return value;
        }
    }
    return null;
};

/**
 * This could benefit from separating the API calls into separate files
 * based on the domain model. For example, a file for Project, a file for
 * Trustline, etc.
 * 
 * @typedef {Object} ProjectInfo
 */
export const XrplPayloadService = {
    async getMintTokenPayload (project, role, wallet )  {   
        const projectRole = {
            "classic_address": wallet.classic_address,
            "roleType": role,
        }

        let grapheneProject = {
            "id": project.id,
            "status": project.status,
            "epp": project.epp,
            "addressRoles": [projectRole],
        } 

        return await Axios.post(`${apiConfig().apiBaseUrl}/payload/project/mint`, grapheneProject);
    },
    // async sendMintTx (tx)  {    
    //     return await Axios.put(`${apiConfig().apiBaseUrl}/payload/project/mint`, tx);
    // },
    async getIssuerAccountSettingsPayload (domain, projectId)  {    
        return await Axios.post(`${apiConfig().apiBaseUrl}/payload/tlcs/issuer/accountset`, {domain, projectId});
    },
    // async sendIssuerAccountSettingsTx (tx)  {    
    //     return await Axios.put(`${apiConfig().apiBaseUrl}/payload/tlcs/issuer/accountset`, tx);
    // },
    async getDistributorAccountSettingsPayload (projectId)  {    
        return await Axios.post(`${apiConfig().apiBaseUrl}/payload/tlcs/distributor/accountset`, {projectId});
    },

    async getBurnerAccountSettingsPayload (projectId)  {
        return await Axios.post(`${apiConfig().apiBaseUrl}/payload/tlcs/burner/accountset`, {projectId});
    },

    async postDistributorTrustlinePayload (tlcs)  {    
        return await Axios.post(`${apiConfig().apiBaseUrl}/payload/tlcs/distributor/trustline`, tlcs);
    },

    async postWalletTrustlinePayload (tlcs)  {    
      return await Axios.post(`${apiConfig().apiBaseUrl}/payload/tlcs/wallet/trustline`, tlcs);
    },

    // async postBurnerTrustlinePayload (tlcs)  {    
    //   return await Axios.post(`${apiConfig().apiBaseUrl}/payload/tlcs/burner/trustline`, tlcs);
    // },


    async getAssertionPayload (claimsetId, benefitClaimsAsserted, 
        conveyanceIpfsHash, amount, vintage="2018")  {   

        return await Axios.post(`${apiConfig().apiBaseUrl}/payload/tlcsa/${claimsetId}`, {
            "benefitClaimsAsserted": benefitClaimsAsserted,
            "claimSetId": claimsetId,
            "conveyanceUri": `ipfs://${conveyanceIpfsHash}`,
            "conveyanceIpfs": conveyanceIpfsHash,
            "amount": amount,
            "vintage": vintage
        });
    },
    async postRetirementPayload (retirementRequest)  { 
      return await Axios.post(`${apiConfig().apiBaseUrl}/payload/retire`, retirementRequest);
    },
    async postBridgeAccountsetPayload ()  { 
      return await Axios.post(`${apiConfig().apiBaseUrl}/payload/bridge/accountset`, {});
    },
    async postBridgeTrustlinePayload (bridgeRequest)  { 
      return await Axios.post(`${apiConfig().apiBaseUrl}/payload/bridge/tlcs`, bridgeRequest);
    },
    // async postBridgeCRUsPayload (bridgeRequest)  { 
    //   return await Axios.post(`${apiConfig().apiBaseUrl}/payload/bridge/crus`, bridgeRequest);
    // },
    async postSendCRUsPayload (sendRequest)  {
      return await Axios.post(`${apiConfig().apiBaseUrl}/payload/send/crus`, sendRequest);
    },
    async signAndSendPayload (tx)  {    
      return await Axios.put(`${apiConfig().apiBaseUrl}/payload/signsend`, tx);
    }

};

