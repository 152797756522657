import React, { useEffect, useState } from "react";
import { CgSpinnerTwo } from "react-icons/cg";
import { MdBackup } from "react-icons/md";
import { BiMessageCheck } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";

import { hexToUtf8, dropsToXrp } from "../utils";
import { GrapheneService } from "../services/GrapheneService";

export const WalletInfo = ({ wallet }) => {
  return (

    <div className="flex flex-row p-2 rounded bg-slate-700">
        <div className="flex flex-col">
            <div className="text-lg font-bold text-white-300">
                Default Wallet
            </div>
            <div className="text-lg font-bold text-yellow-300">
                {wallet.name}
            </div>
            <div className="text-lg text-slate-300 italic">
            {wallet.description}
            </div>
            <div className="flex flex-row font-bold text-slate-300 font-mono">
                <div className="w-[100] text-slate-400">ADDRESS:</div> {wallet.classic_address}
            </div>
            <div className="flex flex-row font-bold text-slate-300 font-mono texr-xs">
                <div className="w-[100] text-slate-400">PUBLIC KEY:</div> {wallet.public_key}
            </div>
        </div>

    </div>
  );
};
