import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import jwt from "jsonwebtoken";
import { saveAs } from "file-saver";
import Dropdown from "react-dropdown";

import {
  FaFileDownload,
  FaLink,
  FaExchangeAlt
} from "react-icons/fa";

import { Page } from "../components/Page";
import { Spinner } from "../components/Base";
import { useAuthStore } from "../store";
import { GrapheneService } from "../services/GrapheneService";
// import { TransactionStatusPill, TransactionStatus } from "../components/TransactionBase";
import {apiConfig} from "../env";

export const TransactionStatus = {
    SUCCESS: 'SUCCESS'
  };

export const TransactionStatusPill = ({status}) => {
    return (
      <>

        {status.toUpperCase() === TransactionStatus.SUCCESS && <div className="div-pill bg-green-700 w-fit">{TransactionStatus.SUCCESS}</div>}
 
      </>
    )
};

const TransactionsList = ({ transactions, removeTransaction, wallet }) => {
  const navigate = useNavigate();

  const [uAddress, setUAddress] = useState();

  useEffect(() => {
    let jwt_stored = useAuthStore.getState().jwt;
    let classic_address = jwt.decode(jwt_stored).sub;
    setUAddress(classic_address);

  }, []);



//   {
//     "id": 3,
//     "tx_account": "rnRsLCbVfpQGNQdsjK9QkGwB9wHwLTFyBd",
//     "tx_status": "success",
//     "tx_hash": "8CCB67C7AD9E747C68F090C3E448084FAC03331CFFA7CC7DCF6C4ED851FBD062",
//     "tx_type": "Payment",
//     "tx_amount_drips": 2450000,
//     "tx_fee_drips": 10,
//     "tx_destination": "rKmMWWuGjJTZeZDKaU9kbziv1ofBAtpWWo",
//     "tx_signature": "010D6A649950C8500A177C9F2D4768D687C8FC032402AA10C4298019C63AB1D3786D2689037EFCA7C13EC0B16C316F6AE4CEA22ACD90CD4B350098C8BE29750C",
//     "tx_signing_pub_key": "ED5DB432889ACD3AC45EBC9E4AE1C23E421869982FE761919C81CEB6C9FAA045D6",
//     "tx_sequence": 37343454,
//     "tx_last_ledger_sequence": 37451152,
//     "tx_flags": 0,
//     "tx_date": "1993-05-01 10:55:40",
//     "tx_ledger_index": 37451134,
//     "tx_transaction_index": 0,
//     "tx_transaction_result": "tesSUCCESS",
//     "tx_delivered_amount_drips": 0,
//     "tx_uri": null,
//     "wallet_id": 13
// }

  return (
    <div className="flex flex-col">
      <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
        <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>Type</th>
            <th>Hash</th>
            <th scope="col" className="text-center">
              Actions
            </th> 
          </tr>
        </thead>
        <tbody>
          {wallet && transactions.map((item, index) => (
            <tr key={index} className="mb-1">
              <td className="w-[180]">{item.tx_date}</td>
              <td className="w-[180]"><TransactionStatusPill status={item.tx_status}/></td>
              <td>{item.tx_type}</td>
              <td onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${item.tx_hash}`,'_blank')} className="link-common font-mono">{item.tx_hash}</td>
              <td scope="col" className="text-center">
                <div className="flex flex-row items-center justify-center">
                    <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${item.tx_hash}`,'_blank')} className="text-gray-200 hover:text-yellow-300 m-1">                     
                        <FaExchangeAlt/>
                    </div>
                </div>
              </td> 
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const Transactions = ({
  xumm=null,
  runtime=null}) => {
  const [error, setError] = useState();
  const [transactions, setTransactions] = useState([]);
  const [wallet, setWallet] = useState();

  useEffect(() => {
      GrapheneService.getTransactions().then((res) => {
        console.log("getTransactions res", res);
        let n_transactions = res.data;
        // n_transactions.sort((a, b) => (a.id > b.id ? 1 : -1));
        setTransactions(n_transactions);
      }).catch((e) => {
        console.log("getTransactions err", e);
  
        if(e.message === "Network Error") {
          setError("There was a problem connecting to the network.");
        } else {
            console.log(e.message, e.response.data.message);
            setError(e.message);
            if(e.response.data) setError(e.response.data.message);
        }

      });
  }, []);

  useEffect(() => {
    GrapheneService.getUser().then((res) => {
      console.log("getUser res", res.data);
      const user = res.data;
      GrapheneService.getWallet()
      .then((res) => {
        console.log("getWalletByAddress res", res.data);
        // filter the wallet on the user default wallet
        setWallet(res.data.filter((w) => w.id === user.default_wallet_id)[0]);
      });
    });
  }, [transactions]);


  const removeTransaction = (transaction) => {
    try {
      console.log("removeTransaction", transaction);
      // let jwt_stored = useAuthStore.getState().jwt;
      // let classic_address = jwt.decode(jwt_stored).sub;
      GrapheneService.deleteTransaction(transaction.id).then((res) => {
        console.log("deleteTransaction res", res);
        let n_transactions = [...transactions];
        n_transactions.sort((a, b) => (a.id < b.id ? 1 : -1));
        setTransactions(n_transactions.filter((item) => item.id !== transaction.id));
      });
    } catch (e) {
      
      if(e.message === "Network Error") {
        setError("There was a problem connecting to the network.");
      } else {
          console.log(e.message, e.response.data.message);
          setError(e.message);
          if(e.response.data) setError(e.response.data.message);
      }
    }
  };

  return (
    <Page xumm={xumm} runtime={runtime}>
      <div className="p-2 flex flex-col justify-start">
        {error && (
          <div className="rounded bg-red-200 text-red-700 p-1 font-bold">{error}</div>
        )}
        <div className="text-3xl">Ledger Transactions</div>
        <div>Last 20 Items</div>
        {transactions && transactions.length > 0 ? (
          <TransactionsList transactions={transactions} removeTransaction={removeTransaction} wallet={wallet} />
        ) : (
          <>No Transactions</>
        )}
      </div>
    </Page>
  );
};
