import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import {AiFillPushpin, AiFillBank} from "react-icons/ai";
import {FaRegHandshake} from "react-icons/fa";
import {MdAccountBalance} from "react-icons/md";
import {AiFillCheckCircle} from "react-icons/ai";

import { RoleType } from "../utils";

import { Page } from "../components/Page";
import { Spinner, Modal, XummStatusLookup } from "../components/Base";
import { WalletSelection } from "../components/WalletSelection";   
import { SignSendModal } from "../components/SignSendModel";

import { RecursiveHashedInfoViewer } from "../components/RecursiveHashedInfoViewer";
import { ProjectStatusPill, ProjectStatus } from "../components/ProjectBase";
import { GrapheneService } from "../services/GrapheneService";
import { XrplPayloadService } from "../services/XrplPayloadService";

import { apiConfig } from "../env";


export const ProjectWalletModal = ({ 
    showModal, setShowModal, wallets, project, roleType, title="Add Wallet" }) => {

    // const [wallets, setWallets] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [walletId, setWalletId] = useState();
    const [showAddButton, setShowAddButton] = useState(false);

    useEffect(() => {
        setWalletId(null);
    }, [showModal]);


    const handleSetWallet = (id) => {
        console.log("handleSetWallet", id);
        setWalletId(id);
        setShowAddButton(true);
    };

    const handleAddWallet = (project, walletId, roleType) => {
        console.log("handleAddWallet", walletId);
        setLoading(true);
        GrapheneService.addProjectRole(project.id, roleName=roleType, wallet_id=walletId)
        .then((res) => {
            console.log("res", res.data);
            setLoading(false);
            setMessage("Successfully associated wallet.");
            setShowAddButton(false);
            setTimeout(() => {
                setMessage(null);
                setShowModal(false);
            }, 2000);
        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setLoading(false);
        });
    };

    return (
      <>
        <div className="flex flex-row justify-end w-fit">
          <div
            className="btn-common"
            onClick={() => setShowModal(true)}
          >
            {title}
          </div>
        </div>
        {showModal && <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
          <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
            {loading ? <Spinner/> : 
            <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
              <div className="modal-dialog">

                {error && <div className="p-2 error font-bold rounded bg-red-200 text-red-700">{error}</div>}
                {message && <div className="p-2 error font-bold rounded bg-green-200 text-green-700">{message}</div>}

                <div className="modal-header font-bold mb-2">
                  Add a Wallet to the Project
                </div>
                <div>
                    <WalletSelection filterBridge={true} wallets={wallets} setWalletId={handleSetWallet}/>
                </div>    
                <div className="modal-content flex flex-col">
                  <div className="flex flex-row justify-end">
                    {showAddButton && <div className="btn-common m-1" onClick={()=>handleAddWallet(project, walletId, roleType)}>Add Wallet</div>}
                    <div className="btn-common m-1" onClick={()=>setShowModal(false)}>Cancel</div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>}
      </>
    );
  };
  

export const ProjectModeLinks = ({project}) => {

    return (
        <div className="m-1">
            {project?.ipfs_hash && <>           
                <div onClick={()=>window.open(`https://peach-genetic-iguana-803.mypinata.cloud/ipfs/${project?.ipfs_hash}`,'_blank')} className="text-gray-200 hover:text-cyan-400 text-2xl flex hover:underline hover:cursor-pointer tooltip">
                 <AiFillPushpin className="ml-1 mr-3"/> <span className="font-mono text-xs hidden md:block">{project?.ipfs_hash}</span>
                 <span className="tooltip-text text-xs">EPP URI</span>
                </div>                    
            </>}

            {project?.nft_tx &&
            <>
                <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${project?.nft_tx.tx_hash}`,'_blank')} className="text-gray-200 text-2xl flex hover:underline hover:cursor-pointer hover:text-cyan-400 tooltip">
                 <FaRegHandshake className="ml-1 mr-3"/> <span className="font-mono text-xs hidden md:block">{project?.nft_tx.tx_hash}</span>
                 <span className="tooltip-text text-xs">NFT TXID</span>
                </div>
            </>}

            {project?.issuer_accountset_tx &&
            <>
                <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${project?.issuer_accountset_tx.tx_hash}`,'_blank')} className="text-gray-200 text-2xl flex hover:underline hover:cursor-pointer hover:text-cyan-400 tooltip">
                <AiFillBank className="ml-1 mr-3"/> <span className="font-mono text-xs hidden md:block">{project?.issuer_accountset_tx.tx_hash}</span>
                <span className="tooltip-text text-xs">ISSUER SET TXID</span>
                </div>
            </>}
        </div>
    );

};

export const ProjectModeButton = ({projectStatus, role, wallet, onClickHandler}) => {
    useEffect(() => {
        console.log("ProjectModeButton", projectStatus, role);
    }, [projectStatus, role]);

    return (
        <>
            {/* {projectStatus}-[{role}] */}
            {/* {JSON.stringify(wallet)} */}
            {projectStatus === ProjectStatus.CREATED && 
                role === RoleType.projectOwner && 
                <div onClick={onClickHandler} className="btn-common bg-green-800">Pin Project IPFS</div>}

            {[ProjectStatus.CREATED, ProjectStatus.PINNED, ProjectStatus.MINTED].includes(projectStatus) && 
                role === RoleType.distributor && 
                <div onClick={onClickHandler} className="btn-common bg-green-800">
                Setup Distributor Account</div>}

            {projectStatus == ProjectStatus.PINNED && 
                role === RoleType.projectOwner && 
                <div onClick={onClickHandler} className="btn-common bg-green-800">
                    Mint Project NFT</div>}

            {projectStatus === ProjectStatus.MINTED && 
                role === RoleType.projectOwner &&
                <div onClick={onClickHandler} className="btn-common bg-green-800">
                    Setup Issuer Account</div>}
            
            {projectStatus === ProjectStatus.ISSUER_SET && 
                role === undefined &&
                <div onClick={onClickHandler} className="btn-common bg-green-800">
                    Claim Distributor Role</div>}

            {projectStatus === ProjectStatus.ISSUER_SET && 
                role === RoleType.distributor &&
                <div onClick={onClickHandler} className="btn-common bg-green-800">
                    Setup Distributor Account</div>}

            {projectStatus === ProjectStatus.DISTRIBUTOR_SET && 
                role === undefined && 
                wallet.is_burner &&
                <div onClick={onClickHandler} className="btn-common bg-green-800">
                    Claim Burner Role</div>}  

            {projectStatus === ProjectStatus.DISTRIBUTOR_SET && 
                role === RoleType.burner && 
                wallet.is_burner &&
                <div onClick={onClickHandler} className="btn-common bg-green-800">
                    Setup Burner Account</div>}  
        </>
    );
};

export const Project = ({
    xumm=null,
    runtime=null}) => {
    const { id } = useParams();

    const [error, setError] = useState();
    const [project, setProject] = useState();
    const [projectWallets, setProjectWallets] = useState();
    const [projectStatus, setProjectStatus] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [nftInfo,setNftInfo] = useState();
    const [role, setRole] = useState();

    const [distributorTxHash,setDistributorTxHash] = useState();
    const [wallet, setWallet] = useState();

    // sign and send modal
    const [showModalTx, setShowModalTx] = useState(false);
    const [txPayload, setTxPayload] = useState(null);
    const [txResult, setTxResult] = useState(null);
    const [txStatusMessage, setTxStatusMessage] = useState(null);

    //associate wallet
    const [showModalWallet, setShowModalWallet] = useState(false);

    const [wallets, setWallets] = useState([]);

    useEffect(() => {
        GrapheneService.getProject(id).then((res) => {
            console.log("res", res.data);
            let foundProject = res.data;
            setProjectWallets(res.data.project_wallets);
            setProject(foundProject);
            setProjectStatus(foundProject.status);
            
        }).catch((err) => {
            console.log("err", err);
            setError(err.message);
        });

    },[]);

    useEffect(() => {
        if(txResult && txResult?.tx_transaction_result === "tesSUCCESS"){
            setIsLoading(true);
            GrapheneService.getProject(id).then((res) => {
                console.log("res", res.data);
                let foundProject = res.data;
                setProjectWallets(res.data.project_wallets);
                setProject(foundProject);
                setProjectStatus(foundProject.status);
                setIsLoading(false);
            }).catch((err) => {
                console.log("err", err);
                setError(err.message);
                setIsLoading(false);
            });

        } else if (txResult && txResult?.tx_transaction_result ) {
            setError(`There was an issue when attempting: ${txResult?.tx_transaction_result}`);
        }
    }, [txResult]);


    useEffect(() => {
        if(showModalWallet){
            setIsLoading(true);
            GrapheneService.getWallets().then((r) => {
                console.log("getWallets response: ", r);
                setWallets(r.data);   
                setIsLoading(false);
            }).catch((e) => {
                console.log(e);
                setError("Problem getting wallets.");
                setIsLoading(false);
            });
        }
    }, [showModalWallet]);


    useEffect(() => {
        console.log("project", project);
        if(project){
            setIsLoading(true);
            GrapheneService.getUser().then((res) => {
                console.log("getUser res", res.data);
                const user = res.data;
                GrapheneService.getWallet()
                .then((res) => {
                    console.log("getWalletByAddress res", res.data);
                    // filter the wallet on the user default wallet
                    const n_wallet = res.data.filter((w) => w.id === user.default_wallet_id)[0];
                    console.log("n_wallet", n_wallet);
                    setWallet(n_wallet);

                    const roleType = projectWallets.find((pw) => {
                        return pw.wallet_id === n_wallet.id && parseInt(pw.project_id) === parseInt(id)
                    })?.role;
                    setRole(roleType);

                    setIsLoading(false);
                });
            })
            .catch((e) => {
                console.log(e);
                if(e.message === "Network Error")
                    setError("Could not connect to the Graphene API. Please check your connection.");
                else
                    setError(e.message);
    
                setIsLoading(false);
            });
        }
    }, [project]);

    const handlePinIPFS = (project, role, wallet) => {
        console.log("handlePinIPFS");
        setIsLoading(true);
        GrapheneService.postPinProjectIPFS(project, role, wallet)
        .then((res) => {
            console.log("pin result", res.data);
            setProjectStatus(ProjectStatus.PINNED);
            setProject(res.data);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });
    };

    const handleMintProjectNFT = (project) => {
        console.log("handleMintProjectNFT", project);
        setIsLoading(true);

        XrplPayloadService.getMintTokenPayload(project, role, wallet)
        .then((res) => {
            console.log("res", res.data);
            // show the sign and send modal
            setIsLoading(false);
            setTxPayload(res.data);
            setShowModalTx(true);
            
        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });

    };

    const handleSetIssuerAccountAttributes = (project) => {

        setIsLoading(true);
        console.log("handleSetIssuerAccountAttributes", project, project.epp.domain);

        XrplPayloadService.getIssuerAccountSettingsPayload(project.epp.domain, project.id)
        .then((res) => {
            console.log("res", res.data);
            setIsLoading(false);
            setTxPayload(res.data);
            setShowModalTx(true);

        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });
    };

    const handleModeHandlerLookup = (status, project, role, wallet) => {
        switch(status){
            case ProjectStatus.CREATED:
                return handlePinIPFS(project, role, wallet);
            case ProjectStatus.PINNED:
                return handleMintProjectNFT(project);
            case ProjectStatus.MINTED:
                if (role === RoleType.projectOwner)
                    return handleSetIssuerAccountAttributes(project); 
            case ProjectStatus.ISSUER_SET:
                if (role === undefined)
                    return handleClaimDistributorRole(project);
                if (role === RoleType.distributor)
                    return handleSetDistributorAccountAttributes(project);   
            case ProjectStatus.DISTRIBUTOR_SET:
                if (role === undefined && wallet.is_burner)
                    return handleClaimBurnerRole(project); 
                if (role === RoleType.burner && wallet.is_burner)
                    return handleSetBurnerAccountAttributes(project);     
        }
    };


    const handleClaimDistributorRole = (project) => {
        console.log("handleClaimDistributorRole", project);
        setIsLoading(true);
        GrapheneService.addProjectRole(project.id, RoleType.distributor)
        .then((res) => {
            console.log("res", res.data);
            
            console.log("projectWallets before:", projectWallets);
            let n_wallets = [...projectWallets];
            n_wallets.push(res.data);
            console.log("projectWallets after:", n_wallets);
            
            // determine the role
            const roleType = n_wallets.find((pw) => {
                return pw.wallet_id === wallet.id && parseInt(pw.project_id) === parseInt(id)
            })?.role;

            console.log("roleType", roleType);
            setRole(roleType);
            setProjectWallets(n_wallets);


            // setProjectStatus(ProjectStatus.);
            setIsLoading(false);
        })  
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });
    };

    const handleClaimBurnerRole = (project) => {
        console.log("handleClaimBurnerRole", project);
        setIsLoading(true);
        GrapheneService.addProjectRole(project.id, RoleType.burner)
        .then((res) => {
            console.log("res", res.data);
            
            console.log("projectWallets before:", projectWallets);
            let n_wallets = [...projectWallets];
            n_wallets.push(res.data);
            
            // determine the role
            const roleType = n_wallets.find((pw) => {
                return pw.wallet_id === wallet.id && parseInt(pw.project_id) === parseInt(id)
            })?.role;

            console.log("roleType", roleType);
            setRole(roleType);
            setProjectWallets(n_wallets);
        
            setIsLoading(false);
        })  
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });
    };

    const handleSetDistributorAccountAttributes = (project) => {
        console.log("handleSetDistributorAccountAttributes", project);
        setIsLoading(true);
        XrplPayloadService.getDistributorAccountSettingsPayload(project.id)
        .then((res) => {
            console.log("res", res.data);
            setIsLoading(false);
            setTxPayload(res.data);
            setShowModalTx(true);
        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });

    };

    const handleSetBurnerAccountAttributes = (project) => {
        console.log("handleSetBurnerAccountAttributes", project);
        setIsLoading(true);
        XrplPayloadService.getBurnerAccountSettingsPayload(project.id)
        .then((res) => {
            console.log("res", res.data);
            setIsLoading(false);
            setTxPayload(res.data);
            setShowModalTx(true);
        })
        .catch((err) => {
            console.log("err", err);
            setError(err.message);
            setIsLoading(false);
        });
    };

    return (
        <Page xumm={xumm} runtime={runtime}>
            <div className="p-2">
                {error && <div className="p-2 error font-bold rounded bg-red-200 text-red-700">{error}</div>}
                
                <div className="flex flex-row justify-between">
                    <div className="text-3xl">Project</div>
                    <div className="flex flex-row justify-end">
                        {role && role == RoleType.distributor && 
                            <ProjectWalletModal setShowModal={setShowModalWallet} showModal={showModalWallet} project={project} wallets={wallets} roleType={RoleType.bridge} title="Associate Bridge Wallet"/> }
                    </div>
                </div>
                
                <div className="text-lg">{project?.epp?.name}</div>
                {project?.domain && <div className="font-mono font-bold text-lg">DOMAIN {project?.domain}</div>}
                <div className="font-mono">ID {id}</div>
                {project && wallet &&              
                    <div>  
                        {/* {role}     */}
                        {txStatusMessage && 
                            <div className="flex flex-row p-3 m-2 rounded bg-slate-600 border-cyan-100 border text-cyan-100 font-bold text-xl">
                                {XummStatusLookup[txStatusMessage].icon} {XummStatusLookup[txStatusMessage].message}
                            </div>}                           

                        <div className="flex flex-row rounded-lg bg-slate-900 justify-between w-full items-center">
                            <div className="items-center p-1 flex flex-row">
                                <ProjectStatusPill status={projectStatus}/>
                                {role != null && <div className="ml-2">Wallet Role: <span className="text-cyan-300 font-bold">{role}</span></div>}
                                {isLoading && <span className="ml-2"><Spinner/></span>}
                            </div>
                            <div>
                                <ProjectModeButton 
                                    setProject={setProject}
                                    projectStatus={projectStatus} 
                                    role={role} 
                                    wallet={wallet}
                                    onClickHandler={()=>handleModeHandlerLookup(projectStatus, project, role, wallet, setProjectStatus)}/>                            
                            </div>
                        </div>
                        {/* start of project Owner */}

                        <ProjectModeLinks projectStatus={projectStatus} role={role} project={project} nftInfo={nftInfo} />

                        {wallet?.domain && project?.epp?.domain === wallet?.domain && projectStatus == 'minted' && 
                                <div className="text-green-500 font-bold flex flex-row items-center">
                                    Issuer domain matches <AiFillCheckCircle className="text-lg"/></div> }
                        {/* end of project Owner */}
                        {/* start of distributor */}
                        {( projectStatus === 'distributorSet' ) && role === RoleType.distributor && 
                        <>
                            <div className="mb-3">
                            {projectStatus == 'distributorSet' && distributorTxHash &&                                
                                <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${distributorTxHash}`,'_blank')} className="text-gray-200 text-2xl flex hover:underline hover:cursor-pointer hover:text-cyan-400">
                                <MdAccountBalance className="ml-3 mr-3"/> 
                                    <span className="font-mono text-xs hidden md:block">{distributorTxHash}</span>
                                </div>}
                            </div>
                        </>}
                        
                        <RecursiveHashedInfoViewer hashedInfo={project.epp} showTitle={false}/>
                    </div>
                }
            </div>
            <SignSendModal 
                setTxResult={setTxResult}
                showModal={showModalTx} 
                setShowModal={setShowModalTx}
                payload={txPayload}/>
        </Page>
    );
};