import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import { GrapheneService } from "../services/GrapheneService";
import { useAuthStore } from "../store";
import { XrplPayloadService } from "../services/XrplPayloadService";

export const RetireModal = ({
    showModal, 
    setShowModal, 
    trustlineClaimsetId, 
    setRetirePayload,
    setShowSignSendModal}) => {

  const [error, setError] = useState("");
  const [retireAmount, setRetireAmount] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Retire", retireAmount);
    
    const retirementRequest = {
        "amount": retireAmount,
        "grapheneTlcsId": trustlineClaimsetId,
    };
    XrplPayloadService.postRetirementPayload(retirementRequest)
    .then(r=>{
        // console.log(r);
        setRetirePayload(r.data);
        setShowModal(false);
        setShowSignSendModal(true);
    })
    .catch(e=>{
        console.log(e);
        setError(e);
    });
  };

  return (
    
    <>
    {showModal && 
    <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
        <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
            <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">

                {error && <div className="error rounded bg-red-200 text-red-700">{error}</div>}

                <div className="text-lg">Use Your XRPL Wallet to Retire</div>
                <form onSubmit={handleSubmit} className="flex flex-col p-1">
                {error && <p className="error">{error}</p>}
                <div className="flex flex-col justify-start mb-2">
                    <label htmlFor="retireAmount">Amount To Retire</label>
                    <input
                        className="text-sm font-mono rounded border-2 border-slate-300 p-2"
                        type="number"
                        name="retireAmount"
                        value={retireAmount}
                        onChange={(event) => setRetireAmount(event.target.value)}/>
                </div>
                <div className="flex flex-row justify-end">
                    <button className="btn-cancel" type="cancel" onClick={()=>setShowModal(false)}>Cancel</button>
                    {retireAmount>0 && <button className="btn-common" type="submit">Retire</button>}
                </div>

                </form>
            </div>
        </div>
        
    </div>}
   </> 
  );
}

