import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jwt from "jsonwebtoken";

import {BsBoxArrowUpRight} from "react-icons/bs";

import {GiStoneBridge} from "react-icons/gi";
import {FaUserTie} from "react-icons/fa";
import {AiFillFire} from "react-icons/ai";

import { GrapheneService } from "../services/GrapheneService";
import { useAuthStore } from "../store";

import { Page } from "../components/Page";
import {Spinner} from "../components/Base";
import { ReservePills } from "../components/PillsWidgets";
import { RetireModal } from "../components/RetireModal";
import { SignSendModal } from "../components/SignSendModel";

import {fromHexToCurrency} from "../utils";
import {apiConfig} from "../env";

export const AccountLinesList = ({wallet, accountLines, setShowModal, setTrustlineClaimsetId}) => {

    const isIssuer = (accountLine) => {
        if (accountLine.limit === 0 && accountLine.limit_peer > 0){
            return true 
        } else return false;

    };

    const handleRetire = (accountLine) => {
        console.log("Retire", accountLine);
        setTrustlineClaimsetId(accountLine.tlcs_id);
        setShowModal(true);
    };

    return (
        <div className="flex flex-col">

            <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
                <thead>
                    <tr>
                        {/* <th className="font-bold text-left">Role</th> */}
                        <th className="font-bold text-left">Counter Party</th>
                        <th className="font-bold text-right">Balance</th>
                        <th className="font-bold text-right">Line Amount</th>
                        {/* <th className="font-bold text-right">Actions</th> */}
                    </tr>
                </thead>
                <tbody>
                {accountLines.map((accountLine, index) => {
                return (
                    <tr key={index} className="mb-1">
                        {/* <td className="text-left ">{isIssuer(accountLine) ? <>Issuer</> :<>Distributor</>}</td> */}
                        <td onClick={()=>window.open(`${apiConfig().ledgerExplorer}/accounts/${accountLine.account}`, "_blank")}
                            className="text-left  font-mono link-common">
                            {accountLine.account}
                        </td>
                        <td className="text-right font-mono 
                            font-bold text-xl text-yellow-300 
                            flex flex-row justify-end">        
                            <div className="mr-1">{accountLine.balance}</div> 
                            <div>{fromHexToCurrency(accountLine.currency).replace(/\W/g, '')}</div>
                        </td>
                        <td>
                        {isIssuer(accountLine) ? <>
                            <div className="text-right font-mono font-bold text-xl text-yellow-300">{accountLine.limit_peer} {fromHexToCurrency(accountLine.currency).replace(/\W/g, '')}</div>                        
                        </> : 
                        <>
                         <div className="text-right font-mono font-bold text-xl text-yellow-300">{accountLine.limit} {fromHexToCurrency(accountLine.currency).replace(/\W/g, '')}</div>                       
                        </>}
                        </td>


                    </tr>
                );
            })}
            </tbody>
            </table>
        </div>
    );
};

export const AccountInfoDetails = ({accountInfo}) => {
    return (
        <div className="flex flex-col w-full mt-2">
            <div className="font-bold text-2xl">Account Data</div>
            <div className="flex flex-row justify-between">
                <div className="font-bold text-left w-1/2">Field</div>
                <div className="font-bold text-left w-1/2">Value</div>
            </div>
            {Object.keys(accountInfo).map((key, index) => {
                return (
                    <div className="flex flex-row justify-between" key={index}>
                        <div className="text-left w-1/2">{key}</div>
                        <div className="text-left w-1/2 font-mono">{accountInfo[key]}</div>
                    </div>
                );
            })}
        </div>
    );
};


export const Wallet = ({
    xumm=null,
    runtime=null}) => {

    const { id } = useParams();

    const [error, setError] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [wallet, setWallet] = useState();
    const [loading, setLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [trustlineClaimsetId, setTrustlineClaimsetId] = useState();

    const [showSignSendModal, setShowSignSendModal] = useState(false);
    const [retirePayload, setRetirePayload] = useState();
    const [txResult, setTxResult] = useState();

    const [walletId, setWalletId] = useState(id);

    useEffect(() => {
        console.log("Wallet");
        setLoading(true);
        GrapheneService.getWalletById(id)
        .then((r) => {
            console.log(r.data);
            setWallet(r.data);
            setLoading(false);
        })
        .catch((e) => {
            console.log(e);
            setLoading(false);
        });

    }, [id, walletId]);

    const setDefaultWallet = (wallet_id) => {
        console.log("setDefaultWallet", wallet_id);
    
        // get the jwt from the store
        let jwt_stored = useAuthStore.getState().jwt;
        console.log("jwt:", jwt.decode(jwt_stored));
        let email = jwt.decode(jwt_stored).sid;
        console.log("email:", email, wallet_id);
        GrapheneService.updateUser(email, wallet_id)
          .then((r) => {
            console.log(r);
            setWallet(r.data.default_wallet);
            
            useAuthStore.getState().setWallet(r.data.default_wallet);
            setSuccessMessage(
              `Wallet ${r.data.default_wallet.name} set as default`
            );
            setTimeout(() => {
                setWalletId(r.data.default_wallet.id);
                setSuccessMessage(null);
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
          });
      };


    return (
        <Page xumm={xumm} runtime={runtime}>
            <div className="p-2">
                {error && <div className="p-2 font-bold error rounded bg-red-200 text-red-700">{error}</div>}
                {successMessage && 
                <div className="m-2 alert-success text-center flex flex-col">
                  <div>{successMessage}</div>
                </div>}

                
                <div className="flex flex-col justify-between">
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center text-3xl">
                            {wallet?.is_burner && 
                            <div className="items-center m-2 bg-slate-600 rounded-full p-2">
                            <span><AiFillFire className="text-pink-500"/></span>
                            </div>}

                            {/*determine if bridge wallet is set*/}
                            {wallet?.is_bridge && 
                            <div className="m-2 bg-slate-600 rounded-full p-2 relative w-[48] h-[48]">
                                <GiStoneBridge className="text-pink-500 relative bottom-2 right-[3] text-4xl"/>
                            </div>}

                            {wallet?.is_buyer && 
                            <div className="items-center m-2 bg-slate-600 rounded-full p-2">
                            <span><FaUserTie className=" text-pink-500"/></span>
                            </div>}
                        
                            <span>Wallet</span>
                        </div>
                        <div>
                            {wallet && wallet.id != useAuthStore.getState().wallet.id && <span 
                            onClick={() => setDefaultWallet(wallet?.id)}
                            className="btn-common">Make Default Wallet</span>}

                            {wallet && wallet.id == useAuthStore.getState().wallet.id && <span 
                            onClick={() => setDefaultWallet(wallet?.id)}
                            className="text-green-400 uppercase bold">Default Wallet</span>}
                        </div>

                    </div>   
                       
                          
                    <div className="flex flex-col text-left">
                        {/* <div className="link-common text-2xl font-mono" onClick={()=>window.open(`${apiConfig().ledgerExplorer}/accounts/${wallet.classic_address}`)}>
                            {wallet?.classic_address}
                        </div> */}
                    </div>   
                </div>
                {wallet && wallet?.accountData?.Account && 
                <div className="flex flex-col justify-start">
                    <div className="text-2xl text-cyan-300">{wallet?.name}</div>
                    <div className="flex flex-row">
                        <div className="font-bold mr-2 text-lg font-mono">{wallet && wallet?.accountData?.Account}</div> 
                        <BsBoxArrowUpRight className="link-common mb-1" onClick={()=>window.open(`${apiConfig().ledgerExplorer}/accounts/${wallet?.accountData?.Account}`,'_blank')}/>
                    </div>
                    <AccountInfoDetails accountInfo={wallet.accountData}/>
                </div>}
                {loading && <Spinner/>}

                {wallet && wallet.reserves && wallet.reserves.length>0 &&
                    <ReservePills reserves={wallet.reserves}/>
                }
                
                {wallet && wallet.accountLines && wallet.accountLines.length>0 &&
                <div className="flex flex-col mt-2">
                    <div className="font-bold text-2xl">Account Lines</div>
                    <AccountLinesList 
                        wallet={wallet}
                        accountLines={wallet.accountLines} 
                        setShowModal={setShowModal}
                        setTrustlineClaimsetId={setTrustlineClaimsetId}/> 
                </div>}

                <RetireModal 
                    showModal={showModal} 
                    setShowModal={setShowModal} 
                    setRetirePayload={setRetirePayload}
                    setShowSignSendModal={setShowSignSendModal}
                    trustlineClaimsetId={trustlineClaimsetId}/>

                <SignSendModal 
                    setTxResult={setTxResult}
                    showModal={showSignSendModal} 
                    setShowModal={setShowSignSendModal}
                    payload={retirePayload}/>

            </div>
        </Page>
    );
};