import { useEffect, useState } from "react";
import Multiselect from 'multiselect-react-dropdown';

export const ProjectStatus = {
    CREATED: 'CREATED',
    PINNED: 'PINNED',
    MINTED: 'MINTED',
    ISSUER_SET: 'ISSUER_SET',
    DISTRIBUTOR_SET: 'DISTRIBUTOR_SET',
    BURNER_SET: 'BURNER_SET',
  };

export const ProjectStatusPill = ({status}) => {
    return (
      <>
        {status === ProjectStatus.CREATED && <div className="div-pill bg-yellow-700 w-fit">{ProjectStatus.CREATED}</div>}
        {status === ProjectStatus.PINNED && <div className="div-pill bg-pink-700 w-fit">{ProjectStatus.PINNED}</div>}
        {status === ProjectStatus.MINTED && <div className="div-pill bg-purple-700 w-fit">{ProjectStatus.MINTED}</div>}
        {status === ProjectStatus.ISSUER_SET && <div className="div-pill bg-green-700 w-fit">{ProjectStatus.ISSUER_SET}</div>}
        {status === ProjectStatus.DISTRIBUTOR_SET && <div className="div-pill bg-cyan-700 w-fit">{ProjectStatus.DISTRIBUTOR_SET}</div>}
        {status === ProjectStatus.BURNER_SET && <div className="div-pill bg-blue-700 w-fit">{ProjectStatus.BURNER_SET}</div>}    
      </>
    )
};


export const ProjectBenefitsSelection = ({project, setBenefits}) => {
  const [error, setError] = useState();
  const [benefitsList, setBenefitsList] = useState([
      {name: 'Option 1', key: "OPTION_1"},
      {name: 'Option 2', key: "OPTION_2"}]);
  const [selectedValues, setSelectedValues] = useState();
 
  useEffect(() => {

    console.log("ProjectBenefitsSelection", project);

    // now get all the benefits for this project[0].project.modularBenefitProjects
    const benefitsList = project.epp.modularBenefitProjects.map((benefitProject) => {
        const bp = benefitProject.benefitClaims.map((claim) => {
            return {name: claim.name, id: claim.id, quantity: claim.quantity, key: `${claim.name} ${claim.quantity}`}
        });
        return bp;
    });
    // now make that a flat array
    const bflat = benefitsList.flat();
    setBenefitsList(bflat);

  }, [project]);


  const flattenProjectBenefitClaims = (projectItem, selectedList) => {
      console.log("flattenProjectBenefitClaims", projectItem, selectedList);
      const benefitsList = projectItem.epp.modularBenefitProjects.map((benefitProject) => {
          const bp = benefitProject.benefitClaims.map((claim) => {
              return claim;
          });
          return bp;
      });

      // map the selectedList to a lis t of ids
      const selectedListIds = selectedList.map((item) => {
          return item.id;
      });

      // now make that a flat array
      const bflat = benefitsList.flat();
      // filter out the bflat items that share the same id as the selectedList
      const filtered = bflat.filter((item) => {
          return selectedListIds.includes(item.id);
      });
      return filtered;
  };


  const onSelect = (selectedList, selectedItem) => {
      console.log("on select",selectedList, selectedItem);
      const bClaims = flattenProjectBenefitClaims(project,selectedList);
      console.log("bClaims", bClaims);
      setBenefits(bClaims);
  }

  const onRemove = (selectedList, removedItem) => {
      console.log("on remove", selectedList, removedItem);
      setBenefits(flattenProjectBenefitClaims(project,selectedList));
  }


  return (<div className="bg-white">

      {project && <Multiselect
          className="break-words"
          options={benefitsList} // Options to display in the dropdown
          displayValue="key" // Property name to display in the dropdown options
          selectedValues={selectedValues} // Preselected value to persist in dropdown
          onSelect={onSelect} // Function will trigger on select event
          onRemove={onRemove} // Function will trigger on remove event
          placeholder="Select Trustline Benefits"
      />}
  </div>);
};


