import { useEffect, useState } from "react";

import { GrapheneService } from "../services/GrapheneService";
import { Spinner, Dropdown as BaseDropdown } from "../components/Base";

export const WalletSelection = ({setWalletId, wallets=[], filterBridge=true}) => {
    const [error, setError] = useState();
    const [walletOptions, setWalletOptions] = useState([
        { value: '1', label: 'Wallet 1' },
        { value: '2', label: 'Wallet 2' },
        { value: '3', label: 'Wallet 3' }
    ]);

    const [defaultOption, setDefaultOption] = useState();

    useEffect(() => {
        if(wallets && wallets.length > 0) {
            let walletsList = wallets.map((walletItem) => {
                return { value: walletItem.id, label: walletItem.id + ' - ' + walletItem.name}
            });
            console.log("walletsList", walletsList);
            setWalletOptions(walletsList);
            setDefaultOption(walletsList[0]);
            console.log("setting default wallet id", walletsList[0].value);
            setWalletId(walletsList[0].value);
        }     
    }, [wallets]);


    const handleSelect = (e) => {
        const option = e.target;
        const id = option.value;
        console.log(`You selected id:${id}`);
        setWalletId(id);
    };

    return (
        <>
        {wallets && 
            <BaseDropdown 
                className="break-words" 
                options={walletOptions} 
                onChange={handleSelect} 
                value={defaultOption} 
                placeholder="Select an option" />}
        </>
    )
};