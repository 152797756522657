import { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Page } from "../components/Page";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import {FormInputField, ErrorList, Spinner} from "../components/Base";
import imgIcon64 from "../assets/img/graphen_icon2_128.png";
import { GrapheneService } from "../services/GrapheneService";
import { useAuthStore } from "../store";

const loginFields=[
    {
        labelText:"Email address",
        labelFor:"email-address",
        id:"email-address",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"Email address",
        customClass: "w-full rounded-lg mb-2 text-gray-600",   
    },
    {
        labelText:"Password",
        labelFor:"password",
        id:"password",
        name:"password",
        type:"password",
        autoComplete:"current-password",
        isRequired:true,
        placeholder:"Password",
        customClass: "w-full rounded-lg mb-2 text-gray-600",   
    }
]

const LoginForm = ({loginFields, email}) => {

    const navigate = useNavigate();

    const fields=loginFields;
    let fieldsState = {};
    fields.forEach(field=>fieldsState[field.id]='');

    const [loginState,setLoginState]=useState({});
    const [errors,setErrors]=useState([]);
    const [successMessage,setSuccessMessage]=useState(null);

    // useEffect(()=>{
    //     console.log("email set: ",email);
    //     loginState["email-address"]=email;
    // },[email])

    const handleChange=(e)=>{
        console.log("handleChange: ",e.target.id,e.target.value);
        setLoginState({...loginState,[e.target.id]:e.target.value})
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        console.log("loginState: ",loginState);

        const errors=[];
        if(!loginState["email-address"]){
            errors.push("Email address is required");
        }
        if(!loginState["password"]){
            errors.push("Password is required");
        }
        if(errors.length>0){
            setErrors(errors);
            return;
        } else {
            setErrors([]);
            setErrors([]);
            console.log("no errors");
            // navigate("/signup");
            GrapheneService.auth(loginState["email-address"], loginState["password"])
            .then(r=>{
                console.log("auth response: ",r);
                setErrors([]);
                setSuccessMessage(`login of ${loginState["email-address"]} successful`);
                Axios.defaults.headers.common['Authorization'] = `Bearer ${r.data.access_token}`;
                useAuthStore.getState().setJwt(r.data.access_token);
                useAuthStore.getState().setIsAuthorized(true);
                setTimeout(()=>{
                    navigate("/dashboard");
                },2000);
            })
            .catch(e=>{
                console.log(e);
                if(e.response && e.response.data) {
                    setErrors([e.response.data.error]);
                } else if (e.message) {
                    setErrors([e.message]);
                } else {
                    setErrors(["Error creating user"]);
                }
            });
        }    
    };


    return(
        <form className="mt-1">
            {errors && errors.length>0 && 
            <div className="m-2 alert-danger text-center">
                <ErrorList errors={errors}/>
            </div>}
            {successMessage ?
            <div className="m-2 alert-success text-center flex flex-col">
                <div>{successMessage}</div> 
                <div className="flex flex-row w-full justify-center"><Spinner/></div>
            </div>:
            <>          
                <div>
                {
                    fields.map(field=>
                            <FormInputField
                                key={field.id}
                                handleChange={handleChange}
                                // value={loginState[field.id]}
                                labelText={field.labelText}
                                labelFor={field.labelFor}
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                isRequired={field.isRequired}
                                placeholder={field.placeholder}
                                customClass={field.customClass}
                        />
                    
                    )
                }
                </div>
                <div className="flex flex-row items-center justify-between">
                    <div onClick={(e)=>handleSubmit(e)} className="btn-common flex w-[300]">
                        Login
                    </div>
                </div>
            </>}
      </form>
    )
}

export const Login = ({
    xumm=null,
    runtime=null}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const verified = searchParams.get("verified");
    const email = searchParams.get("email");

    useEffect(()=>{
        if(status && status==="VERIFIED"){
            //email-address
        }
    },[email]);
    
    return (
        <div>
            <div className="flex flex-row justify-center mt-4">
                <div className="flex flex-col w-[300]">
                    
                    

                    <div className="text-3xl font-semibold flex fle-row justify-start p-2 items-center">
                        <img src={imgIcon64} alt="graphene" className="mr-2 inline-block h-10 align-middle" />
                        <span className="font-heading">graphene</span><span className="font-heading text-cyan-200">impact</span>
                    </div>

                    {verified && verified==="true" && status && status==="VERIFIED" &&
                    <div className="alert-success text-center">{email} is verified. You can now login using your email address and password you chose.</div>}


                    <div className="text-3xl w-100 text-center">Login</div>
                    <LoginForm loginFields={loginFields} email={email}/>
                    <div className="w-100 text-center">Don't have an account yet? <span onClick={()=>navigate("/signup")} className="ml-3 text-yellow-500 link-common">Signup</span></div>

                    <div className="w-100 text-center">Forget your password? <span onClick={()=>navigate("/reset")} className="ml-3 text-yellow-500 link-common">Send Reset Email</span></div>


                    <div className="w-100 text-center">Not Ready? <span onClick={()=>navigate("/")} className="ml-3 text-yellow-500 link-common">Goto Home</span></div>
                </div>
            </div>
        </div>
    );
};