import { useNavigate } from "react-router-dom";
/**
 * xumm link handling for external links must be handled by the app
 * sdk, so we need some way to pass the link to the app as well as 
 * just dispay it as a link for webapp users.
 * 
 * links is a list of objects with the following structure:
 * [{ 'title': 'title', 'url': 'url' }]
 * 
 * @param {*} param0 
 */
export const LinksViewer = ({links, showTitle=false, title="Important Links"}) => {

    const navigate = useNavigate();
  
    /**
     * this handles the xApp and webapp cases for external links
     * 
     * @param {*} url 
     */
    let handleClickedLink = (url) => {
        console.log("clicked link", url);
        navigate(url);
    };
  
    let renderUrls = (links) => {
      return links.map((key, index) => (
          <div className="w-32 h-8 text-yellow-200
            bg-slate-600 m-1 rounded p-1 break-words" key={index}>
              <div onClick={()=>handleClickedLink(key.url)} className="text-xs font-bold text-cyan-300 
                hover:underline cursor-pointer">{key.title}</div> 
          </div>
      ));
    };
  
    return (
      <div className="w-full flex flex-col">
        {showTitle && <div className="text-2xl text-white">{title}</div>}
        <div className="flex flex-wrap">
          {renderUrls(links)}
        </div>
      </div>
    );
  };