import deployment from './deployment.json';

export const apiConfig = () => {
    const config = {
        prd: {
            apiBaseUrl: 'https://api.grapheneimpact.org/v1',
            webBaseUrl: 'https://grapheneimpact.org',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
            chain: 'mainnet',
            ledgerExplorer: 'https://livenet.xrpl.org',
        },
        dev: {
            apiBaseUrl: 'https://devapi.grapheneimpact.org/v1',
            webBaseUrl: 'https://dev.grapheneimpact.org',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
            chain: 'testnet',
            ledgerExplorer: 'https://testnet.xrpl.org',
        },
        local: {
            apiBaseUrl: 'http://localhost:5000',
            webBaseUrl: 'http://localhost:3020',
            pinataGateway: 'https://peach-genetic-iguana-803.mypinata.cloud/ipfs',
            chain: 'testnet',
            ledgerExplorer: 'https://testnet.xrpl.org',
        },
    };
    return config[deployment.env];
};

export const xummConfig = {
    name: 'grapheneimpact.org',
    env: 'prd',
    AppId: 'ab4018f7-ea84-4d05-99ec-1f21115ba398',
};
