import icon512 from "../assets/img/graphen_icon2_512.png";
import {AiFillPushpin} from "react-icons/ai";
import {FaRegHandshake} from "react-icons/fa";
import {MdGeneratingTokens} from "react-icons/md";
import { FaCashRegister } from "react-icons/fa";

import imgIcon64 from "../assets/img/centigrade_icon2_128.png";

export const HeroImage = () => {
    return (
        <div className="flex justify-center w-full h-128 
            bg-cover bg-[url('./assets/img/centigrade-bg.png')]" >
            <div className="w-3/4 md:w-1/2 p-3 mt-8 mb-8">
                <div className="flex flex-col justify-center items-center h-full">
                    {/* <img src={icon512} className="w-48 h-48 m-3" /> */}
                    <div className="text-center text-4xl md:text-4xl 
                        font-bold text-gray-200">
                        Welcome to 
                    </div> 
                    <div className="text-6xl mb-2">
                        <img src={imgIcon64} alt="graphene" 
                        className="mr-2 inline-block h-10 align-middle scale-150" />
                    </div>
                    <div className="text-center md:text-lg font-bold text-slate-300 m-2">
                        Centigrade FOUNDRY uses the power of the XRP Blockchain and the IWA EP standard to track and manage carbon offset projects.</div>
                </div>
            </div>
      </div>
    );
  };

  export const HomeList = () => {
    // const { name, env, mintPrice } = appConfig();
    return (
        <div className="p-6">
            <div className="grid gap-3 text-white sm:grid-cols-1 md:grid-cols-3">
                <div className="flex flex-row justify-start rounded-md border-2 p-2">
                <div className="items-baseline p-2"><MdGeneratingTokens className="w-24 h-24"/></div>
                <div className="items-baseline p-2 text-xl">Generate Ecological Project or Program (EP) NFTs easily on the XRP Blockchain</div>
                </div>
                <div className="flex flex-row justify-start rounded-md border-2 p-2">
                <div className="items-baseline p-2 text-4xl"><FaRegHandshake className="w-24 h-24"/></div>
                <div className="items-baseline p-2 text-xl">Create Semi-Fungible Token (SFT) Trustlines Against a Project's Proposed Benefit Claims</div>
                </div>
                <div className="flex flex-row justify-start rounded-md border-2 p-2">
                <div className="items-baseline p-2 text-4xl"><FaCashRegister className="w-24 h-24"/></div>
                <div className="items-baseline p-2 text-xl">Assert that Ecological Benefits are Verifiable and Distribute Funds</div>
                </div>
            </div>
        </div>
    );
};
