import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {FaHandHoldingMedical, FaExchangeAlt, FaDatabase, FaCheckCircle} from 'react-icons/fa';
import {AiFillBank} from "react-icons/ai";
import {GoAlert} from "react-icons/go";

import { RoleType, hexToUtf8 } from "../utils";
import { Page } from "../components/Page";
import { GrapheneService } from "../services/GrapheneService";
import { Spinner, Dropdown as BaseDropdown, Dropdown } from "../components/Base";
 
import { SignSendModal } from "../components/SignSendModel";
import {BenefitClaimsPills} from '../components/PillsWidgets';
import { ProjectBenefitsSelection, ProjectStatus } from "../components/ProjectBase";

import { apiConfig } from '../env';
import { XrplPayloadService } from "../services/XrplPayloadService";



export const TrustlineTonnageIdUploader = (
    {
        setIsLoading, 
        tonnageTable, 
        setTonnageTable, 
        setError,
        type='.csv'}) => {

    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
 
    const changeHandler = (event) => {
          setSelectedFile(event.target.files[0]);
          setIsSelected(true);
      };
  
    const handleSubmission = () => { 
        setIsLoading(true);
        console.log("handleSubmission", selectedFile);
        GrapheneService.uploadTonnageTableDocument(selectedFile)
        .then((response) => {
            console.log(response);
            setIsLoading(false);
            if(response.data.tonnage > 0){
                setTonnageTable(response.data);
            } else {
                setError("No new credits found in the document. Credit IDs must be unique for a specific Trustline SFT. If you upload ids into multiple trustlines only new IDs will be recognized. Please check the document and try again.");
            }
        })
        .catch((e) => {
            console.log(e);
            if(e.message === "Network Error") {
                setError("Could not connect to the Graphene API. Please check your connection.");
            } else {
                console.log(e.message, e.response.data.message);
                setError(e.message);
                if(e.response.data)
                    setError(e.response.data.message);
            }
            setIsLoading(false);
        });
      };
  
    return (
        <>
            {tonnageTable ? 
            <div>
                <div className="p-3 m-2 rounded bg-green-200 border-green-600 border text-green-700 items-center flex flex-row">
                    <AiOutlineCheckCircle className="text-3xl"/>Upload Successful
                </div>
            </div>:
            <div className="mt-2">
            <label className="block mb-2 
            text-gray-900 dark:text-white" 
            forname="file_input">Upload Tonnage ID CSV</label>
            <input id="file_input" className="p-1 block w-full text-gray-900 
            border border-gray-300 rounded
            cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
            type="file" name="file" onChange={changeHandler}/>
            {isSelected &&<div>
                    <div className="btn-common" onClick={handleSubmission}>Upload</div>
                </div>}            
            </div>}        
        </>

    );
  }


export const TrustlineStatus = ({status}) => {
    return (
      <>
        {status === 'CREATED' && <div className="div-pill bg-yellow-700 w-fit">CREATED</div>}
        {status === 'ON_CHAIN' && <div className="div-pill bg-cyan-700 w-fit">ON CHAIN</div>}
      </>
    )
};

const TrustlinesList = ({trustlines, wallet, setTrustline}) => {

    const navigate = useNavigate();

    const ipfsToPinGateway = (ipfsEndpoint) => {
        console.log("api config called", apiConfig());
        const parts = ipfsEndpoint.split('/');
        const hash = parts[parts.length - 1];
        return `${apiConfig().pinataGateway}/${hash}`;
    };

    const showRole = (item, wallet) => {

        console.log("showRole",item.name, item, wallet);
        
        //check if this is the burn wallet
        if (item.burnerAccount && parseInt(item.burnerAccount.id) === parseInt(wallet.id)){
            return "BURNER";
        }

        if (wallet?.is_bridge){
            return "BRIDGE";
        }

        if (wallet?.is_buyer){
            return "BUYER";
        }

        //filter the addressRoles for the wallet address
        // console.log("showRole", item, wallet);
        const addressRoleWallet = item.addressRoles.filter((addressRole) => {
            return addressRole.classic_address === wallet.classic_address;
        });
        console.log("addressRoleWallet", addressRoleWallet);
        return addressRoleWallet[0]?.roleType;
    };

    

    return (
        <div className="flex flex-col">
            <table className="table-auto border-separate border-spacing-y-1 border-spacing-x-1 text-left">
                <thead>
                    <tr>
                        {/* <th>Status</th> */}
                        <th>Claimset ID</th>
                        <th>Role</th>
                        <th>Project Name</th>
                        <th>Benefit Claims</th>
                        <th>Total Amount</th>
                        <th>Burn Wallet</th>
                        {/* <th>Date</th> */}
                        {/* <th>NFT</th> */}
                        {/* <th>Role</th> */}
                        <th scope="col" className="text-center">Actions</th> 
                    </tr>
                </thead>
                <tbody>
            {trustlines.map((item, index) => (
                <tr key={index} className="mb-1">
                    {/* <td className=" align-top"><TrustlineStatus status="foo"/></td> */}
                    <td className="align-top text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300" onClick={()=>navigate(`/trustline/${item.g_id}`)}>{item.id}</td>
                    <td className="align-top font-mono">{showRole(item, wallet)}</td>
                    <td className="align-top">
                        <div className="align-top text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300" onClick={()=>navigate(`/project/${item.grapheneProjectId}`)}>{item.projectName}</div>
                    </td>
                    <td className="align-top"><BenefitClaimsPills benefitClaims={item.benefitClaims} showTitle={false}/></td>
                    <td className="text-2xl font-mono font-bold items-start align-top">
                    {item.value} {item.tokenSymbol}</td>
                    

                    <td>
                        {item.burnerAccount&& <div 
                            onClick={()=>navigate(`/wallet/${item.burnerAccount.id}`)}
                            className="flex flex-row justify-start items-center link-common">
                            {item.burnerAccount.name}
                            {item?.burnerTrustlineTxHash ? 
                                <FaCheckCircle className="text-green-500 ml-1 text-2xl"/>:
                                <GoAlert className="text-yellow-500 ml-1 text-2xl"/>}
                        </div>}
                    </td>


                    {/* <td scope="col" className="text-center align-top">role</td> */}
                    <td scope="col" className="text-center align-top">
                        {item.tx && 
                        <div className="flex flex-row justify-center items-center w-full"> 
                            <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${item.tx.hash}`,'_blank')} className="text-gray-200 hover:text-yellow-300 m-1">                     
                                <FaExchangeAlt/>
                            </div>
                            {item?.ipfsHash && <div onClick={()=>window.open(ipfsToPinGateway(item.ipfsHash),'_blank')} className="text-gray-200 hover:text-yellow-300 m-1"><FaDatabase/></div>}

                            {showRole(item, wallet) === RoleType.burner && <>

                                {!item?.burnerTrustlineTxHash ?
                                <div onClick={()=>setTrustline(item)} className="text-gray-200 hover:text-yellow-300 m-1">                     
                                    <FaHandHoldingMedical/>
                                </div>:
                                <>
                                    <div className="text-gray-200 m-1">
                                        <FaHandHoldingMedical className="opacity-50"/>
                                    </div>
                                    <div className="text-gray-200 m-1 text-2xl cursor-pointer" 
                                    onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${item?.burnerTrustlineTxHash}`,'_blank')}><AiFillBank/>
                                    </div>
                                </>}
                            </>}

                            {showRole(item, wallet) === RoleType.buyer && <>
                                <div className="text-white m-1" onClick={()=>setTrustline(item)}>
                                    <FaHandHoldingMedical/>
                                </div>
                            </>}

                        </div>}                     
                    </td>
                </tr>
            ))}
            </tbody>
            </table>
        </div>
        
    );
};


export const TokenSymbolSelection = ({onChange, tokenSymbol}) => {
    return (
        <>
            <input
                name="tokenSymbol" 
                onClick={onChange}
                onChange={onChange} 
                value={tokenSymbol}
                className="m-1 p-1 rounded" type="text" 
                placeholder="Token Symbol" />
        </>
    )
};

export const ProjectItemSelection = ({setProjectId, status=ProjectStatus.BURNER_SET}) => {
    const [error, setError] = useState();
    const [projectDomains, setProjectDomains] = useState([
        { value: 'test1.com', label: 'Project 1 - test1.com' },
        { value: 'test2.org', label: 'Project 2 - test2.org' },
        { value: 'test3.net', label: 'Project 3 - test3.net' }
    ]);

    const [defaultOption, setDefaultOption] = useState();

    useEffect(() => {
        GrapheneService.getProjects().then((r) => {
            let projects = r.data;
            
            let projectList = projects.filter(p => p.status == status).map((projectItem) => {
                return { value: projectItem.id, label: projectItem.id + ' - ' + projectItem.project_name + ' - ' + projectItem.domain}
            });
            console.log("projectList", projectList);
            setProjectDomains(projectList);
            setDefaultOption(projectList[0]);
            setProjectId(projectList[0].value);
        }).catch((e) => {
            console.log("error", e);
            setError(e);
        });
        
    }, []);


    const handleSelect = (e) => {
        const option = e.target;
        const id = option.value;
        console.log(`You selected id:${id}`, option);
        // console.log('You selected id:', id);
        setProjectId(id);
    };

    return (
        <>
        {projectDomains && 
            <BaseDropdown 
                className="break-words" 
                options={projectDomains} 
                onChange={handleSelect} 
                value={defaultOption} 
                placeholder="Select an option" />}
        </>
    )
};

export const TrustlineImportModal = ({showModal, setShowModal, setProjectId}) => {
    const [error, setError] = useState();

    return (
        <>
            {showModal && 
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
                <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
                    <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
                        {error && <div className="error rounded bg-red-200 text-red-700 p-1">{error}</div>}
                        <div className="text-lg">Import Trustline For Project</div>
                        <div className="mb-2">
                            <label htmlFor="project">Choose the Project</label>
                            <ProjectItemSelection setProjectId={setProjectId} status={ProjectStatus.BURNER_SET}/>
                        </div>
                        <div className="flex flex-row justify-end">
                            <button className="btn-cancel" onClick={()=>setShowModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};


export const MessageStatus = ({statusLookup, status}) => {
  
    return (
      <>
        {statusLookup[status].icon} {statusLookup[status].message}
      </>
    )
  };
  

export const TrustlineModalForm = ({
    showModal,
    setShowModal,
    setShowModalTx,
    setTxPayload,
    trustlines,  
    setTrustlines
}) => {

    const [error, setError] = useState();

    const [projectDomain, setProjectDomain] = useState();
    const [benefits, setBenefits] = useState();
    const [trustlineValueSelected, setTrustlineValueSelected] = useState(0);
    const [projectItem, setProjectItem] = useState();
    const [loading, setLoading] = useState(false);

    /** state for the tx listening */
    const [txStatusMessage, setTxStatusMessage] = useState(null);
    const [formReady, setFormReady] = useState(false);

    const [tonnageTable, setTonnageTable] = useState(null);
    const [vintages, setVintages] = useState([]);
    const [burnWalletId, setBurnWalletId] = useState(null);
    const [tokenSymbol, setTokenSymbol] = useState("");

    const [formState, setFormState] = useState({
        tokenSymbol: '',
        benefitClaims: [],
        domain: '',
        trustlineValue: 0,
        issuerAddress: '',
        burnWalletId: null
    });

    useEffect(() => {
        setError(null);
        setTxStatusMessage(null);
        setTrustlineValueSelected(null);
        setLoading(false);
        setTonnageTable(null);
        setTokenSymbol("");
        setBurnWalletId(null);
    }, [showModal]);

    useEffect(() => {
        console.log("=== CHANGE formState", formState);
        setFormReady(formIsValid());
    }, [formState]);


    const handleCancel = () => {
        setFormState({
            tokenSymbol: '',
            benefitClaims: [],
            domain: '',
            trustlineValue: 0,
            issuerAddress: '',
            burnWalletId: null,
            vintages: [],
        });
        setProjectItem(null);
        setTrustlineValueSelected(null);
        setFormReady(false);
        setError(null);
        setShowModal(false);
        setTonnageTable(null);
        setTokenSymbol("");
        setBurnWalletId(null);
        setVintages([]);

    };

    const formIsValid = () => {

        if(formState.tokenSymbol == ''){
            return false;
        }
        if(projectItem == null){
            return false;
        }
        if(trustlineValueSelected == null){
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        setError(null);
        // console.log('submit projectItem trustline',projectItem);

        console.log("formState before submit", formState, vintages, vintages[0].value);
        const trustlineInfo = {
            benefitClaims: benefits,
            tokenSymbol: formState.tokenSymbol,
            issuerAddress: projectItem.nft_tx.tx_account,
            vintages: formState.vintages,
            value: trustlineValueSelected,
            nftToken: {NFTokenID: projectItem.nft_tx.tx_hash, URI: projectItem.nft_tx.tx_uri},
            projectName: projectItem.epp.name,
            projectId: projectItem.id,
            creditUniqueIds: tonnageTable.credit_unique_ids,
            burnWalletId: burnWalletId,
        };

        console.log("trustlineInfo", trustlineInfo);
        setLoading(true);

        XrplPayloadService.postDistributorTrustlinePayload(trustlineInfo)
        .then((r) => {
            console.log("postDistributorTrustlinePayload response",r.data);
            setLoading(false);
            setTxPayload(r.data);
            setShowModal(false);
            setShowModalTx(true);
        })
        .catch((e) => {
            console.log("handle error", e);
            if(e.message === "Network Error") {
                setError("Could not connect to the Graphene API. Please check your connection.");
            } else if(e?.response?.data) {
                setError(e.response.data.message);
            } else {
                console.log("how?", e);
                setError("what to do?");
                // if(e.response.data) setError(e.response.data.message);
            }
            setLoading(false);
        });

    };

    const handleChange = (e) => {
        console.log("handleChange", e.target.name, e.target.value);
        setFormState({ ...formState, [e.target.name]: e.target.value });
        setFormReady(formIsValid());
    };

    const handleTonnageTableUpload = (tonnageTable) => {
        setTonnageTable(tonnageTable);

        // set vintage selections
        const v_options = tonnageTable.vintages.map((vintage) => {
            return { value: vintage, label: vintage }
        });
        setVintages(v_options);
        setFormState({ ...formState, vintages: [v_options[0].value] });
        setError(null);
        setTrustlineValueSelected(tonnageTable.tonnage);
        setFormReady(formIsValid());
    };

    const generateRandomString = (len) => {
        let randomString = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        
        for (let i = 0; i < len; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          randomString += characters.charAt(randomIndex);
        }
        
        return randomString;
    };

    const removeNonAlphaCharacters = (str)=>{
        return str.replace(/[^a-zA-Z]+/g, '');
    };

    const generateTokenSymbol = () => {
        const tokenSymbol = removeNonAlphaCharacters(`${projectItem.epp.name.substring(0, 3)}${generateRandomString(4)}`.toUpperCase());
        console.log("generateTokenSymbol", tokenSymbol);
        setFormState({ ...formState, tokenSymbol: tokenSymbol });
        setTokenSymbol(tokenSymbol);
        setFormReady(formIsValid());
    };

    const handleProjectSelect = (projectId) => {
        // setTrustlineValueSelected(null);
        console.log("form state before", formState);
        GrapheneService.getProject(projectId).then((r) => {
            console.log("getProject", r.data);
            setProjectItem(r.data);
            setFormState({ ...formState, domain: r.data.epp.domain });
            setProjectDomain(r.data.epp.domain);
            
            console.log("=== project wallets", r.data.project_wallets);
            if(r.data?.project_wallets?.length > 0){
                const burnWallet = r.data.project_wallets.filter((pw) => {
                    console.log("pw", pw, pw.role, pw.role == RoleType.burner);
                    return pw.role == RoleType.burner;
                });
                console.log("burnWallet", burnWallet);
                if(burnWallet.length > 0){
                    setBurnWalletId(burnWallet[0].wallet_id);
                }
            }
            
            setFormReady(formIsValid());

        }).catch((e) => {
            console.log(e);
            if(e.message === "Network Error") {
                setError("Could not connect to the Graphene API. Please check your connection.");
            } else {
                // console.log(e.message, e.response.data.message);
                // console.log(e);
                setError(e.message);
                // if(e.response.data)
                //     setError(e.response.data.message);
            }

        });

    };

    const setBenefitsList = (benefitsList) => {
        let total = benefitsList.reduce((acc, obj) => {
            return acc + obj.quantity
        }, 0);
        setBenefits(benefitsList);
        setFormReady(formIsValid());
    };

    return (
        <>
            {showModal &&  
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
                <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
                    <div className="rounded bg-cyan-200 w-[450] p-2 text-slate-800">
                        <div className="text-lg mb-2 font-bold">Create A New CRU Trustline (SFT)</div>
                        {error && <div className="mb-1 break-words error rounded bg-red-200 text-red-700 p-1">{error}</div>}
                        {txStatusMessage && 
                            <div className="flex flex-row p-3 m-2 
                            rounded bg-slate-600 border-cyan-100 
                            border text-cyan-100 font-bold text-xl">
                            </div>}                           
                        {loading && <div className="text-lg flex flex-row w-full justify-center">
                            <Spinner textColor="yellow-200"/></div>}
                        
                        {trustlineValueSelected > 0 && <div className="flex flex-row w-full justify-center font-mono text-3xl">
                            {trustlineValueSelected} tCO2e
                        </div>}

                        

                        {tonnageTable ?
                        <div className="bg-green-200 rounded-lg text-green-800 p-1 flex flex-row">
                            Tonnage Table Successfully Uploaded</div>
                        :
                        <div className="mb-2 flex flex-col w-full">
                            <TrustlineTonnageIdUploader setIsLoading={setLoading} setTonnageTable={handleTonnageTableUpload} setError={setError}/>
                        </div>}


                        {tonnageTable?.vintages && <div className="flex flex-row w-full justify-center font-mono text-lg">vintages:{JSON.stringify(tonnageTable?.vintages)}</div>}

                        {tonnageTable?.vintages && 
                        <div className="flex flex-row w-full justify-center font-mono text-lg">
                            <Dropdown
                                className="break-words"
                                options={vintages}
                                onChange={(e) => {
                                    console.log("vintage selected", e.target.value);
                                    setFormState({ ...formState, vintage: e.target.value });
                                }}
                                value={vintages[0]}
                                placeholder="Select an vintage"
                            />
                        </div>}

                        <div className="mb-2">
                            <label htmlFor="project">Choose the Project</label>
                            <ProjectItemSelection 
                                setProjectId={handleProjectSelect} 
                                status={ProjectStatus.BURNER_SET}/>

                            {projectDomain && <div className="mt-1 mb-2"><span className="mr-2 font-bold">Project Domain:</span>{projectDomain}</div>}
                        </div>
                        {projectItem && <div className="mb-2">
                            <label htmlFor="project">Choose the Benefits</label>
                            <ProjectBenefitsSelection project={projectItem} setBenefits={setBenefitsList} />
                        </div>}

                        {projectItem && trustlineValueSelected > 0 && 
                        <div className="mb-2 flex flex-col w-full">
                            <label  className="mr-2" htmlFor="project">Token Symbol <span onClick={()=>generateTokenSymbol()} className="btn-common">Generate</span></label>
                            <TokenSymbolSelection onChange={handleChange} tokenSymbol={tokenSymbol} />
                        </div>}

                        <div className="flex flex-row justify-end mt-2">
                             <button className="btn-cancel" onClick={()=>handleCancel()}>Cancel
                             </button>                            
                             {formReady && <button className="btn-common" onClick={()=>handleSubmit()}>Create Project Trustline</button>}
                        </div>

                    </div>
                </div>
                
            </div>}

        </>
    )
};


export const Trustlines = ({
    xumm=null,
    runtime=null}) => {
    const [error, setError] = useState();
    const [showModalTrustline, setShowModalTrustline] = useState(false);
    const [showModalImport, setShowModalImport] = useState(false);
    
    const [trustlines, setTrustlines] = useState();
    const [loading, setLoading] = useState(false);

    // sign and send modal
    const [showModalTx, setShowModalTx] = useState(false);
    const [txPayload, setTxPayload] = useState(null);
    const [txResult, setTxResult] = useState(null);

    const [wallet, setWallet] = useState(null);

    useEffect(() => {
        if(txResult){
            console.log("txResult", txResult);
        }
    }, [txResult]);

    useEffect(() => {

        setLoading(true);       

        GrapheneService.getUser()
        .then((r) => {
            console.log("user's default wallet", r.data.default_wallet);
            setWallet(r.data.default_wallet);
            
            GrapheneService.getClaimsetTrustlines().then((r) => {
                console.log("getTrustlines", r.data);
                // const trustlinesData = r.data;
                setTrustlines(r.data);
                setLoading(false);
            }).catch((e) => {
                console.log(e);
                if(e.message === "Network Error")
                    setError("Could not connect to the Graphene API. Please check your connection.");
                else
                    setError(e.message);
            });


        })
        .catch((e) => {
            console.log(e);
            setError("GETUSER Could not connect to the Graphene API. Please check your connection.");
            setLoading(false);
        });

    }, []);

    const handleImport = (projectId) => {
        console.log("handleImport");

        setLoading(true);
        GrapheneService.getTrustlinesForProject(projectId)
        .then((r) => {
            console.log("getTrustlinesForProject", r.data);
            const nTl = [...r.data]
            setTrustlines(nTl);
            setLoading(false);
            setShowModalImport(false);
        })
        .catch((e) => {
            console.log("error",e);
            if(e.message === "Network Error")
                setError("Could not connect to the Graphene API. Please check your connection.");
            else
                setError(e.message);

            setLoading(false);
        });
    };

    const handleTrustlineSelect = (trustline) => {
        console.log("handleTrustlineSelect", trustline);

        const vintageYears = trustline.carbonResourceUnits.map((cru) => cru.vintage_year);

        const creditUniqueIds = trustline.carbonResourceUnits.map((cru) => cru.credit_unique_id);

        const trustlineInfo = {
            ...trustline,
            vintages: vintageYears,
            value: trustline.carbonResourceUnits.length,
            creditUniqueIds: creditUniqueIds,
            burnWalletId: wallet.id,
        };
        // use the graphene project id for the xrpl project id
        // @TODO the external id of the document should be the IWA project id
        // trustlineInfo.projectId = trustline.grapheneProjectId;

        console.log("trustlineInfo", trustlineInfo);
        setLoading(true);

        // post the trustline payload for this wallet
        XrplPayloadService.postWalletTrustlinePayload(trustlineInfo)
        .then((r) => {
            console.log("postWalletTrustlinePayload response",r.data);
            setTxPayload(r.data);
            setShowModalTx(true);
            setLoading(false);
        })
        .catch((e) => {
            console.log("handle error", e);
            if(e.message === "Network Error") {
                setError("Could not connect to the Graphene API. Please check your connection.");
            } else if(e?.response?.data) {
                setError(e.response.data.message);
            } else {
                console.log("how?", e);
                setError("what to do?");
            }
            setLoading(false);
        })
    };

    return (
        <Page xumm={xumm} runtime={runtime}>
            <div className="p-2 flex flex-col w-full justify-start">
                {error && 
                <div className="error rounded bg-red-200 text-red-700 p-1">{error}</div>}               
                <div className="flex flex-row justify-between">
                    <div className="text-3xl flex flex-row">
                        <span>Trustline Claimsets</span>              
                    </div>
                    <div className="flex flex-row justify-start">
                        {wallet && 
                        <><div onClick={()=>setShowModalTrustline(true)} 
                        className="btn-common">Create Trustline SFT</div></>}                        
                    </div>                  
                </div>
                {loading && <div className="text-lg flex flex-row w-full justify-start ml-2">
                    <Spinner textColor="yellow-200"/></div>} 
                <div>
                    {trustlines && trustlines.length>0 ? 
                        <TrustlinesList 
                            trustlines={trustlines} 
                            wallet={wallet}
                            setTrustline={handleTrustlineSelect}/>:
                    <div className="text-left text-lg font-bold text-gray-400">No Trustlines</div>}
                </div>
            </div>
            <TrustlineModalForm 
                showModal={showModalTrustline} 
                setShowModal={setShowModalTrustline}
                setShowModalTx={setShowModalTx}
                setTxPayload={setTxPayload} 
                trustlines={trustlines}    
                setTrustlines={setTrustlines}/>
            <TrustlineImportModal showModal={showModalImport} 
                setShowModal={setShowModalImport} 
                setProjectId={handleImport}/>
            <SignSendModal 
                setTxResult={setTxResult}
                payload={txPayload}
                showModal={showModalTx} 
                setShowModal={setShowModalTx}/>
        </Page>
    );
};