import React, {useEffect, useState } from "react"
import { useNavigate, Link } from "react-router-dom";
import Axios from "axios";
import jwt from 'jsonwebtoken';
import { LoginModal } from "./LoginModal";
import { useAuthStore } from "../store";
import { LinksViewer } from "./LinksViewer";
import { GrapheneService } from "../services/GrapheneService";

import imgIcon64 from "../assets/img/centigrade_icon2_128.png";



export default function LoginPrompt({
    heading,
    paragraph,
    linkName,
    linkUrl="#",
    forgotPasswordName="Reset",
    forgotPasswordUrl="#",
}){
    const navigate = useNavigate();
    return(
        <div className="mb-1">
            <div onClick={()=>navigate('/login')} className="btn-common mt-1 text-center text font-extrabold text-gray-200">
                {heading}
            </div>
            <p className="m-1 text-center text-sm text-gray-300">
                {paragraph} {' '}
                <Link to={linkUrl} className="font-medium text-yellow-600 hover:text-yellow-500">
                    {linkName}
                </Link>
            </p>
            <p className="m-1 text-center text-sm text-gray-300">
                Forget your password? {' '}
                <Link to={forgotPasswordUrl} className="font-medium text-yellow-600 hover:text-yellow-500">
                    {forgotPasswordName}
                </Link>
            </p>


        </div>
    )
}


export const Header = ({}) => {

    const headerLinks = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Wallets', url: '/wallets' },
        { title: 'Projects', url: '/projects' },
        { title: 'Trustline Claimsets', url: '/trustlines' },
        { title: 'Asserted Claims', url: '/claims' },
        { title: 'Transactions', url: '/transactions' },
      ]

    /**
     * we want the app to behave the same depending on if
     * this a XAPP or a webapp
     */
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [identity, setIdentity] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        setIsAuthorized(useAuthStore.getState()?.isAuthorized);
        const decoded = jwt.decode(useAuthStore.getState().jwt);
        console.log("Header.js decoded", decoded);
        setIdentity(decoded);
        if(useAuthStore.getState()?.isAuthorized){
            GrapheneService.getUser()
            .then((r) => {
                console.log("user",r);
                setWallet(r.data.default_wallet);
                useAuthStore.getState().setWallet(r.data.default_wallet);
                setUser(r.data);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
        
                if(e.message === "Network Error")
                    setError("Could not connect to the Graphene API. Please check your connection.");
                else
                    setError(e.message);
        
                setLoading(false);
            });
        }
        
    }, [useAuthStore.getState()?.isAuthorized]);

    useEffect(() => {
        setWallet(useAuthStore.getState()?.wallet);
    }, [useAuthStore.getState()?.wallet]);


    const logout = () => {
        console.log("logout");
        setWallet(null);
        setIsAuthorized(false);
        useAuthStore.getState().setIsAuthorized(false);
        setIdentity(null);
        useAuthStore.getState().setJwt(null);
        Axios.defaults.headers.common['Authorization'] = null;
        navigate("/");
    };

    return (
        <>
            <header className="h-fit bg-cyan-800 text-white flex flex-col md:flex-row justify-center md:justify-between items-center md:items-start">
                <div className="text-3xl font-semibold flex flex-row justify-center md:justify-start p-2 items-top">
                    <div
                        className="hover:cursor-pointer"
                        onClick={()=>navigate('/')}
                    ><img src={imgIcon64} alt="graphene" 
                        className="mr-2 inline-block h-10 align-middle" /></div>
                    {/* <span className="font-heading">graphene</span>
                    <span className="font-heading text-cyan-200">impact</span> */}
                </div>
                <div className="flex flex-col w-[250] md:justify-end">
                    {isAuthorized ? 
                        <div className="flex flex-col">
                            <div onClick={()=>logout()} className="m-1 btn-common">Logout {identity.sid}</div>
                            {wallet && 
                                <div className="w-full flex flex-row justify-center md:justify-end">
                                    <div className="m-2 p-1 text-right w-fit mr-1 items-center text-xs bg-green-300 rounded-lg text-green-800">{wallet?.name}</div>
                                </div>} 
                        </div>
                    :
                    <div>
                        <div className="mr-2">
                            <LoginPrompt heading="Login to your account"
                                paragraph="Don't have an account yet? "
                                linkName="Signup"
                                linkUrl="/signup"
                                forgotPasswordName="Reset"
                                forgotPasswordUrl="/reset"/>
                        </div>
                    </div>}


                </div>

            </header> 
            {isAuthorized && <div className="bg-cyan-700">
                <LinksViewer links={headerLinks} />
            </div>}  
        </>
    );
};