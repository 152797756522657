import "./styles.css";
import React, {useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import jwt from 'jsonwebtoken';

// import { Xumm } from "xumm";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { ResetPassword } from "./pages/ResetPassword";
import { Signup } from "./pages/Signup";
import { Wallet } from "./pages/Wallet";
import { Dashboard } from "./pages/Dashboard";
import { Projects } from "./pages/Projects";
import { Project } from "./pages/Project";
import { Trustline } from "./pages/Trustline";
import { Trustlines } from "./pages/Trustlines";
import { Claims } from "./pages/Claims";
import { Transactions } from "./pages/Transactions";

import { useAuthStore } from "./store";
import { Wallets } from "./pages/Wallets";

const PrivateRoute = () => {
    if (useAuthStore.getState().isAuthorized) {
      return <Outlet />;
    } else { 
      console.log("PrivateRoute not active");
      return <Navigate to="/" />;
    }
};
  
export function App() {

    const [isAuthorized, setIsAuthorized] = useState(false);
    // const [runtime, setRuntime] = useState(null);
    // const [xummSDK, setXummSDK] = useState(null);
    const [identity, setIdentity] = useState(null);
    const [clientType, setClientType] = useState(null);
    const [xumm, setXumm] = useState(null);
    const [xummSDK, setXummSDK] = useState(null);
    const [runtime, setRuntime] = useState(null);
    
    return (
        <BrowserRouter>
            <Routes>
                {/* PRIVATE ROUTES */}
                <Route exact path="/trustline/:id" element={<PrivateRoute/>}>
                    <Route exact path="/trustline/:id" element={<Trustline xumm={xumm} runtime={runtime}/>}/>
                </Route>
                <Route exact path="/trustlines" element={<PrivateRoute/>}>
                    <Route exact path="/trustlines" element={<Trustlines xumm={xumm} runtime={runtime}/>}/>
                </Route>
                <Route exact path="/project/:id" element={<PrivateRoute/>}>
                    <Route exact path="/project/:id" element={<Project xumm={xumm} runtime={runtime}/>}/>
                </Route>
                <Route exact path="/projects" element={<PrivateRoute/>}>
                    <Route exact path="/projects" element={<Projects/>}/>
                </Route>
                <Route exact path="/claims" element={<PrivateRoute/>}>
                    <Route exact path="/claims" element={<Claims xumm={xumm} runtime={runtime}/>}/>
                </Route> 
                
                <Route exact path="/transactions" element={<PrivateRoute/>}>
                    <Route exact path="/transactions" element={<Transactions xumm={xumm} runtime={runtime}/>}/>
                </Route> 
                

                <Route exact path="/wallet/:id" element={<PrivateRoute/>}>
                    <Route exact path="/wallet/:id" element={<Wallet xumm={xumm} runtime={runtime}/>}/>
                </Route>
                <Route exact path="/wallets" element={<PrivateRoute/>}>
                    <Route exact path="/wallets" element={<Wallets/>}/>
                </Route>
                <Route exact path="/dashboard" element={<PrivateRoute/>}>
                    <Route exact path="/dashboard" element={<Dashboard/>}/>
                </Route>

                {/* PUBLIC ROUTES */}
                <Route path="/" element={<Home xumm={xumm} runtime={runtime}/>} />  
                <Route path="/login" element={<Login/>} /> 
                <Route path="/reset" element={<ResetPassword/>} /> 
                <Route path="/signup" element={<Signup/>} /> 
            </Routes>
        </BrowserRouter>
    );
}


// Add a request interceptor
Axios.interceptors.request.use(function (config) {

  // if the request token is expired, redirect to login page
  if (useAuthStore.getState()?.jwt && useAuthStore.getState()?.jwt !== "") {
      const decoded = jwt.decode(useAuthStore.getState().jwt);
      const nT = parseInt(Date.now() / 1000);
      const diff = decoded.exp - nT;
      console.log("decoded time exp:", decoded.exp, "now:", nT, "diff:", diff, (decoded.exp < nT));
      if (decoded.exp < nT) {
          useAuthStore.getState().setJwt("");
          useAuthStore.getState().setIsAuthorized(false);
          window.location.href = "/";
      } 
  }

  // Do something before request is sent
  console.log("axios.interceptors.request.use", config.url);
  if (!config.url.includes("auth/token")) {
       Axios.defaults.headers.common['Authorization'] = `Bearer ${useAuthStore.getState().jwt}`;
       config.headers.Authorization = `Bearer ${useAuthStore.getState().jwt}`;
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});