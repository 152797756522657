import { useEffect, useState } from "react";
import { Page } from "../components/Page";
import { Spinner } from "../components/Base";
import { XrplPayloadService } from "../services/XrplPayloadService";
import { GrapheneService } from "../services/GrapheneService";
import { WalletInfo } from "../components/WalletInfo";

export const Dashboard = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [wallet, setWallet] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    setLoading(true);
    GrapheneService.getUser()
    .then((r) => {
        console.log("user",r);
        setWallet(r.data.default_wallet);
        setUser(r.data);
        setLoading(false);
    })
    .catch((e) => {
        console.log(e);

        if(e.message === "Network Error")
            setError("Could not connect to the Graphene API. Please check your connection.");
        else
            setError(e.message);

        setLoading(false);
    });
  }, []);

  return (
    <Page>
        <div className="p-2">
        {error && (
          <div className="p-2 font-bold error rounded bg-red-200 text-red-700">
            {error}
          </div>
        )}
        <div className="flex flex-row justify-between">
          <div className="text-3xl">Dashboard</div>
          <div className="flex flex-row justify-end p-1">
            {/* <div className="flex flex-row">
                <div className="btn-common">Some User Action</div>
            </div> */}
          </div>
        </div>
        {loading && <Spinner />}
        <div className="mt-3">
          {wallet?.id ? <WalletInfo wallet={wallet} /> : <div>No Default Wallet Set</div>}  
        </div>
        
        </div>
    </Page>
  );
};
