// import { convertHexToString, convertStringToHex } from 'xrpl';
import moment from "moment";

export const hexToUtf8 = (hex) =>{
    // Create a new TextEncoder object
    const encoder = new TextEncoder();

    // Encode the hex string to a Uint8Array
    // const hex = "68656c6c6f";
    const uint8array = new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

    // Use the TextDecoder to convert the Uint8Array to a UTF-8 string
    const decoder = new TextDecoder("utf-8");
    const utf8string = decoder.decode(uint8array);

    return utf8string; // Output: "hello"
}


/**
 * convert currency to hex
 * @param currency currency string
 * @returns hex string
 */
export const fromCurrencyToHex = (currency) => {
  if (currency.length > 3) {
    const hex = hexToUtf8(currency.toUpperCase());
    return hex.padEnd(40, '0');
  }
  return currency;
};

/**
 * convert hex to currency
 * @param hex hex string
 * @returns currency string
 */
export const fromHexToCurrency = (hexString) => {
  if (hexString != undefined && hexString.length > 3) {
    const padding = "0";
    const unpaddedHex = hexString.replace(new RegExp(`${padding}*$`), "");
    const utf8 = Buffer.from(unpaddedHex, "hex").toString("utf8");
    return utf8;
  }
  return hexString;
};


export const ellipses = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength) + "...";
  }
};

export const xrpToDrops = (xrp) =>{
  return int(xrp * 1000000);
}
    
export const dropsToXrp = (xrp) =>{
  return xrp / 1000000;
}

export const RoleType = {
  unknown: "UNKNOWN",
	projectOwner: "PROJECT_OWNER",
	distributor: "DISTRIBUTOR",
  burner: "BURNER",
  bridge: "BRIDGE",
  buyer: "BUYER",
}

export const roleTypeName = (roleType) => {
  console.log("roleType", roleType);
	if (roleType === RoleType.unknown || roleType === undefined) {
    return "Unknown";
  }
  let typeName = roleType === RoleType.projectOwner ? "Project Owner" : "Distributor";
  return typeName;
}

export const determineRoleType = (classic_address, addressRoles) => {
  let roleType = RoleType.unknown;
  const userRole = addressRoles.filter(addressRole => addressRole.classic_address === classic_address);
  console.log("userRole", userRole);
  if (userRole.length > 0) {
    roleType = userRole[0].roleType;
  }
  return roleType;
};

export const formatDate = (dateISOString) => {
  var d = new Date(dateISOString);
  var formatteddatestr = moment(d).format('MM/DD/YYYY HH:MM');
  return formatteddatestr;
};
