import React, { useEffect, useState } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { XrplPayloadService, getSetFlagById } from "../services/XrplPayloadService";
import { Spinner } from "./Base";
import {hexToUtf8, fromHexToCurrency} from "../utils";

const MemoItem = ({memo}) => {
    const memoData = JSON.parse(hexToUtf8(memo.Memo.MemoData));
    console.log("MemoItem memodata: ",memoData);

    const [toggleShowTx, setToggleShowTx] = useState(false);

    const handleToggleShowTx = () => {
        setToggleShowTx(!toggleShowTx);
    };

    return (
        <div onClick={()=>setToggleShowTx(!toggleShowTx)} className="flex flex-col justify-between text-xs m-1 bg-slate-800 hover:bg-slate-700 rounded-lg p-1">
            <div className="flex flex-row justify-start items-center">
                <div className="text-base text-white">{memoData.type}</div>
            </div>
            {toggleShowTx && 
            <div className="scrollable-overflow flex flex-col justify-start items-start">
                <pre className="text-white font-bold scrollable-overflow break-words">
                    {JSON.stringify(memoData, null, 3)}</pre>
            </div>}
        </div>
    );
};

const MemoList = ({memos}) => {
    

    return (
        <div className="flex flex-col w-full">
            {memos.map((memo, index)=>{
                return (
                    <div key={index}>
                        <MemoItem memo={memo}/>
                    </div>
                );
            }
            )}
        </div>
    );
};



const ModalPayloadInfo = ({payload, title}) => {
    console.log("payload: ",payload);
    return (
        <div className="flex flex-col w-full">
            <div className="text-2xl">{title}</div>
            {payload?.txjson?.Memos && 
            <div className="flex flex-col justify-between w-full">
                <div className="text-lg font-bold mt-2">Memos</div>
                <div className="text-xs w-full">
                    <MemoList memos={payload.txjson.Memos}/>
                </div>
            </div>}
        </div>
    );
};

// const MintTokenPayloadInfo = ({payload}) => {
//     console.log("MintTokenInfo payload: ",payload);
//     return (
//         <div className="flex flex-col w-full">
//             <div className="text-2xl">Approve Minting of Project Token</div>
//             {payload?.txjson?.Memos && 
//             <div className="flex flex-col justify-between w-full">
//                 <div className="text-lg font-bold mt-2">Memos</div>
//                 <div className="text-xs w-full">
//                     <MemoList memos={payload.txjson.Memos}/>
//                 </div>
//             </div>}
//         </div>
//     );
// };


const PaymentPayloadInfo = ({payload, title}) => {
    return (
        <div className="flex flex-col w-full">
            <div className="text-2xl">{title}</div>

            <div className="flex flex-col justify-center items-center">
                {payload?.txjson?.Amount &&
                <div className="flex flex-col justify-center items-center m-2 p-2 rounded bg-slate-200">
                      
                    <div>
                        <div className="flex flex-row justify-center text-base items-baseline font-bold text-cyan-800 font-mono">
                        <span className="mr-1 text-3xl">{payload.txjson.Amount.value}</span>
                        <span className="mr-1 text-lg">{fromHexToCurrency(payload.txjson.Amount.currency)}</span>
                        </div>
                    </div>
                    <div>Destination</div>
                    <div className="text-xs font-bold text-cyan-800 font-mono break-words">
                        {payload.txjson.Destination}</div>    
                    <div>Issuer</div>
                    <div className="text-xs font-bold text-cyan-800 font-mono break-words">
                        {payload.txjson.Amount.issuer}</div>

                </div>}    
                {payload?.txjson?.Memos && 
                <div className="flex flex-col w-full">
                    <div className="text-lg font-bold mt-2">Memos</div>
                    <div className="text-xs">
                        <MemoList memos={payload.txjson.Memos}/>
                    </div>
                </div>}   
            </div>
         </div>
     );     
 };

const TrustSetPayloadInfo = ({payload, title}) => {
    console.log("TrustSetInfo payload: ",payload);
    return (
        <div className="flex flex-col w-full">
            <div className="text-2xl">{title}</div>
            <div className="flex flex-col justify-center items-center">
                {payload?.txjson?.LimitAmount &&
                <div className="flex flex-col justify-center items-center m-2 p-2 rounded bg-slate-200">

                    <div>
                        <div className="flex flex-row justify-center text-base items-baseline font-bold text-cyan-800 font-mono">
                        <span className="mr-1 text-3xl">{payload.txjson.LimitAmount.value}</span>
                        <span className="mr-1 text-lg">{fromHexToCurrency(payload.txjson.LimitAmount.currency)}</span>
                        </div>
                    </div>
    
                    <div>Issuer</div>
                    <div className="text-xs font-bold text-cyan-800 font-mono break-words">{payload.txjson.LimitAmount.issuer}</div>        

                </div>}    
                {payload?.txjson?.Memos && 
                <div className="flex flex-col w-full">
                    <div className="text-lg font-bold mt-2">Memos</div>
                    <div className="text-xs">
                        <MemoList memos={payload.txjson.Memos}/>
                    </div>
                </div>}   
            </div>
        </div>
    
    );
};

// const AccountSetPayloadInfo = ({payload}) => {
    
//     const [flags, setFlags] = useState(null);

//     useEffect(()=>{
//         const setFlagValue = payload.txjson.SetFlag ? payload.txjson.SetFlag : 0;
//         const setFlagList = setFlagValue.toString().split(",");
//         const flagsList = setFlagList.map((num) => getSetFlagById(parseInt(num, 10)));
//         console.log("flags: ",flagsList);
//         setFlags(flagsList);

//     },[payload]);


//     return (
//         <div className="flex flex-col w-full">
//             <div className="text-2xl">Approve AccountSet</div>
//             {payload?.txjson?.SetFlag && <div className="flex flex-row justify-start">
//                 <div className="text-xs w-[50]">Set Flag</div>
//                 <div className="text-sm font-bold text-cyan-800">
//                     {flags && flags.map((flag, index)=>{
//                         return <div className="mr-2 font-mono" key={index}>{flag?.Corresponding_Ledger_Flag}</div>
//                     })}
//                 </div>
//             </div>}
//             {payload?.txjson?.ClearFlag && <div className="flex flex-row justify-between">
//                 <div className="text-xs w-[50]">Clear Flag</div>
//                 <div className="text-xs">{payload.txjson.ClearFlag}</div>
//             </div>}
//             {payload?.txjson?.Domain && <div className="flex flex-row justify-start">
//                 <div className="text-xs w-[50]">Domain</div>
//                 <div className="text-sm font-bold text-cyan-800 font-mono">{hexToUtf8(payload.txjson.Domain)}</div>
//             </div>}
//             {payload?.txjson?.Memos && <div className="flex flex-col justify-between">
//                 <div className="text-lg font-bold mt-2">Memos</div>
//                 <div className="text-xs">
//                     <MemoList memos={payload.txjson.Memos}/>
//                 </div>
//             </div>}

//         </div>
//     );
// };

const JSONPayloadInfo = ({payload}) => {
    console.log("JSONPayloadInfo payload: ",payload);
    return (
        <div className="flex flex-col w-full">
            <div className="text-2xl">Approve JSON Payload</div>
            <div className="flex flex-col">
                <div className="text-xs">JSON</div>
                <div className="text-xs scrollable-overflow bg-slate-200">
                    <pre>{JSON.stringify(payload.txjson, null, 2)}</pre>
                </div>
            </div>
        </div>
    );
};

export const SignSendModal = ({showModal, setShowModal, payload, setTxResult}) => {

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const makePayloadInfo = (payload) => {

    console.log("makePayloadInfo payload: ",payload);
    // {"type": "AccountSetInfo", "body": {"type": "BRIDGE"}}
    const payloadInfo = {
        "NFTokenMint:base":<ModalPayloadInfo payload={payload} title="Mint the EPP Project NFT"/>,
        "AccountSet:base":<ModalPayloadInfo payload={payload} title="Update the AccountSet"/>,
        "AccountSet:bridge":<ModalPayloadInfo payload={payload} title="Set Bridge Wallet AccountSet"/>,
        "TrustSet:base":<TrustSetPayloadInfo payload={payload} title="Create CRU SFT Trustline"/>,
        "TrustSet:bridge":<TrustSetPayloadInfo payload={payload} title="Create Bridge Trustline"/>,
        "Payment:base":<PaymentPayloadInfo payload={payload} title="Assert Benefit Claim"/>,
        "Payment:retire":<PaymentPayloadInfo payload={payload} title="Retire CRU SFT Tokens"/>,
        "Payment:bridge":<PaymentPayloadInfo payload={payload} title="Bridge CRU SFT Tokens"/>,
        "Payment:sendcru":<PaymentPayloadInfo payload={payload} title="Send CRU SFT Tokens"/>,
    }

    let suffix = "base";
    if (payload.custom_meta.identifier.toLowerCase().startsWith('retire')) {
        suffix = "retire";
    }
    if (payload.custom_meta.identifier.toLowerCase().startsWith('bridge')) {
        suffix = "bridge";
    }
    if (payload.custom_meta.identifier.toLowerCase().startsWith('sendcru')) {
        suffix = "sendcru";
    }


    const payload_type = `${payload.txjson.TransactionType}:${suffix}`;
    console.log("payload_type: ",payload_type);

    const v_r = payloadInfo[payload_type];

    return v_r ? v_r : <JSONPayloadInfo payload={payload}/>;

  };

  const handleSignSend = () => {
    console.log("SignSendModal handleSubmit: ",payload);
    setLoading(true);
    XrplPayloadService.signAndSendPayload(payload)
    .then(r=>{
        console.log("sign and send result:",r.data);
        setLoading(false);
        setSuccessMessage("Transaction sent to XRP Ledger");

        setTxResult(r.data);
        setTimeout(()=>{
            setError(null);
            setSuccessMessage(null);
            setShowModal(false);
        },2000); 
    })
    .catch(e=>{
        console.log(e);

        if(e.message === "Network Error") {
            setError("There was a problem connecting to the network.");
        } else {
            console.log(e.message, e.response.data.message);
            setError(e.message);
            if(e.response.data) setError(e.response.data.message);
        }

        setLoading(false);
        setTimeout(()=>{
            setError(null);
            setSuccessMessage(null);
            setShowModal(false);
        },3000); 
    });

  };

  return (
    
    <>
    {showModal && 
    <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
        <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
            <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">

                {loading && <div className="flex flex-row justify-center items-center">
                    <span className="mr-1 text-slate-600">Sending Signed Tx...</span><Spinner/></div>}
                
                {error && <div className="m-1 error rounded bg-red-200 text-red-700">{error}</div>}
                
                {successMessage && 
                <div className="flex flex-row m-1 p-1 success rounded bg-green-200 text-green-700">
                    <FaCheckCircle className="mr-1 text-2xl text-green-700"/>
                    <div>{successMessage}</div>
                </div>} 

                {payload && 
                <div className="rounded text-xs p-2 text-slate-800">
                    {makePayloadInfo(payload)}
                </div>}
                <div className="flex flex-row justify-end">
                    <button className="btn-cancel" type="cancel" onClick={()=>setShowModal(false)}>Cancel</button>
                    <button className="btn-approve font-bold"  onClick={()=>handleSignSend()}>Approve</button>
                </div>

            </div>
        </div>
        
    </div>}
   </> 
  );
}

